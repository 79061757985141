import React from "react";
import { Divider, Paper, Stack, Typography } from "@mui/material";
import { getBorderRadius } from "../../lib/helpers/utils";
import { PATH as PATH_HOME } from "../root";
import SubpageWrapper from "../../lib/components/atomics/SubpageWrapper";

export const PATH_SEGMENT = "info";
export const PATH = `/${PATH_SEGMENT}`;
export const getPath = () => PATH;

interface InfoPageData {
  title: string;
  subtitle?: string;
  body: string;
}

const InfoBlock: React.FC<InfoPageData> = ({ title, subtitle, body }) => {
  return (
    <Paper
      sx={{
        px: { xs: 2, md: 6 },
        py: 3,
        width: "100%",
        borderRadius: getBorderRadius,
      }}
    >
      <Stack gap={1}>
        <Typography variant="h3">{title}</Typography>
        <Typography variant="subtitle1">{subtitle}</Typography>
        <Divider sx={{ my: 1 }} />
        <Typography variant="body1">{body}</Typography>
      </Stack>
    </Paper>
  );
};
const Info = () => {
  return null;
  //TODO: Fetch data
  const dataFromDB = [
    {
      title: "Viktig informasjon om gasspris",
      subtitle: "Prisutvikling Propan",
      body: "Lorem ipsum lorem ipsum\nloren ipsum",
    },
  ] as InfoPageData[];
  const pagetitle = "Viktig informasjon"; // Unsure if this is supposed to be a dynamic or static variable;

  return (
    <SubpageWrapper title={pagetitle} backRef={PATH_HOME}>
      {dataFromDB.map((item, index) => (
        <InfoBlock key={index} {...item} />
      ))}
    </SubpageWrapper>
  );
};

export default Info;
