import { Button, ButtonProps, Link } from "@mui/material";

import { LinkProps } from "react-router-dom";
import MUIIcon from "./MUIIcon";
import { FCWithChildren } from "../../../types";

type ButtonWithHrefProps = ButtonProps & {
  href?: LinkProps["to"];
};

interface StaticButtonProps extends ButtonWithHrefProps {
  arrow?: "before" | "after";
  iconColor?: ButtonProps["color"];
  square?: boolean;
  transparent?: boolean;
  outgoingLink?: boolean;
}

const StaticButton: FCWithChildren<StaticButtonProps> = ({
  arrow,
  iconColor: iconColorFromProp,
  children,
  square,
  transparent,
  href,
  sx,
  startIcon: startIconFromProp,
  endIcon: endIconFromProp,
  disabled,
  outgoingLink,
  ...buttonProps
}) => {
  const iconColor = disabled ? "disabled" : iconColorFromProp || "secondary";
  const startIcon = arrow === "before" && (
    <MUIIcon name="arrow_back" color={iconColor} />
  );
  const endIcon = arrow === "after" && (
    <MUIIcon name="arrow_forward" color={iconColor} />
  );
  const linkIcon = outgoingLink && (
    <MUIIcon name="open_in_new" color={iconColor} />
  );
  const squareSx = square
    ? {
        borderRadius: "6px",
        px: 2,
      }
    : {};
  const outgoingLinkProps = outgoingLink
    ? { target: "_blank", rel: "noopener noreferrer" }
    : {};
  const linkProps = href
    ? { href, LinkComponent: Link, ...outgoingLinkProps }
    : {};

  return (
    <Button
      variant={transparent ? "text" : "contained"}
      startIcon={startIcon || startIconFromProp}
      endIcon={endIcon || linkIcon || endIconFromProp}
      disableElevation
      sx={{
        px: 3,
        py: 0.5,
        display: "flex",
        justifyContent: "space-between",
        ...squareSx,
        ...sx,
      }}
      disabled={disabled}
      {...buttonProps}
      {...linkProps}
    >
      {children}
    </Button>
  );
};

export default StaticButton;
