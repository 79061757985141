export const TIME_MINUTE = 1000 * 60;
export const TIME_HOUR = TIME_MINUTE * 60;
export const TIME_DAY = TIME_HOUR * 24;

export const TOKEN_AUTHORIZATION_VALIDITY = TIME_MINUTE * 15;
export const CUSTOMER_CHANGE_REQUEST_VALIDITY = TIME_DAY;

/**
 * Sentry error sample rate, should be 100% of errors
 */
export const SENTRY_SAMPLE_RATE = 1.0;

/**
 * Sentry traces sample rate, should be 0% of traces
 */
export const SENTRY_TRACES_SAMPLE_RATE = 0.01;
