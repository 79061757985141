import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IS_DEV } from "../../variables";

const unlistedErrors: {
  context: string;
  coreError: string;
  error: AnyError;
  stack?: string;
}[] = [];

interface RecursiveObject {
  [key: string]: RecursiveObject | string;
}

export const errorLib = {
  login: {
    "Customer not found": "invalid_user",
    "Code not valid": "invalid_login_code",
    "Invalid code format": "invalid_login_code_format",
    "Token expired": "login_code_expired",
    "Login not found": "login_not_found",
    "Max retries, please try again later": "max_retries",
    "NetworkError when attempting to fetch resource.":
      "login_cant_connect_to_server",
    "Customer is relocated": "customer_is_relocated",
  },
  invoice: {
    "Not Found": "invoice_not_found",
  },
  meter: {
    // "MeterReading already exists": "meter_already_exists",
    "MeterReading already exists": "meter_already_exists_temp",
  },
} as RecursiveObject;
const traverseErrorLib = (key: string) => {
  const path = key.split(".");
  let current: RecursiveObject | string = errorLib;
  for (let i = 0; i < path.length; i++) {
    if (typeof current === "string") return;
    const pathElement = path[i];
    current = current[pathElement];
    if (!current) return;
  }
  if (typeof current !== "string") return;
  return current;
};

const getCoreError = (e: AnyError): string => {
  if (typeof e === "string") return e;
  if (e?.bodyJson?.error) {
    try {
      const parsed = JSON.parse(e.bodyJson.error);
      // zod error
      return parsed[0].message || e.bodyJson.error;
    } catch {
      return e.bodyJson.error;
    }
  }
  if (e?.bodyString) {
    const message = e.bodyString.match(/"error":"(.*)[^\\]"/);
    if (message) return message[1];
  }
  if (e?.message) return e.message;
  return "Unknown Error";
};

type ErrorHandlerOverload = {
  (error?: Error): string | undefined;
  (context: string, error?: Error): string | undefined;
};

export const useErrorMessage = () => {
  const { t } = useTranslation();

  return useCallback<ErrorHandlerOverload>(
    /**
     * Either use as `er(error)` or as `er('logincontext', error)` in case recieved
     * error doesnt contain useful data or to group errors
     */
    (param1, param2 = undefined) => {
      const usesContext = typeof param1 === "string";
      const context = usesContext ? param1 + "." : "";
      const error = (usesContext ? param2 : param1) as Error | undefined;

      if (!error) return undefined;
      const coreError = getCoreError(error);
      const errorString = traverseErrorLib(context + coreError);

      if (IS_DEV && !errorString) {
        const stack = new Error().stack;
        const newError = {
          context,
          coreError,
          error,
          where: stack,
        };
        console.warn(
          `UnlistedError: Error '${coreError}'${
            usesContext ? ` with context '${param1}'` : ""
          } in component '${
            stack?.match(/\n([^@]+)/)?.[1] ?? "unknown"
          }'. Data:`,
          {
            data: {
              error,
              stack,
            },
          }
        );
        if (!unlistedErrors.find((e) => e.coreError === coreError))
          unlistedErrors.push(newError);
        console.log("Log:", unlistedErrors);
      }
      if (errorString) return t("error.message." + errorString);
      if (IS_DEV) return `⚠️ ${coreError}`;
      return t("error.message.catchall");
    },
    [t]
  );
};
