import { useNavigate, useRouteError } from "react-router-dom";
import ErrorPageC from "../lib/components/complex/ErrorPage";
import { PATH } from "./root";

export default function ErrorPage() {
  const navigate = useNavigate();
  const error = useRouteError() as AnyError;
  console.error(error);

  return (
    <ErrorPageC
      navigate={navigate}
      error={error}
      backpath={PATH}
      homepath={PATH}
    />
  );
}
