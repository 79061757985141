import { Icon, IconProps } from "@mui/material";

export interface MUIIconProps extends IconProps {
  name?: string;
  children?: string;
  filled?: boolean;
}
const MUIIcon: React.FC<MUIIconProps> = ({
  name,
  filled,
  children,
  sx = {},
  ...props
}) => (
  <Icon
    sx={{
      ...sx,
      fontVariationSettings: `'FILL' ${filled ? 1 : 0}`,
    }}
    {...props}
  >
    {name || children}
  </Icon>
);

export default MUIIcon;
