import { useTranslation } from "react-i18next";

// 06-09. Formiddag: 09-12. Ettermiddag: 12-18. Kveld: 18-24

export const useGreeting = () => {
  const { t } = useTranslation();

  let greeting = t("general.greeting.evening");
  const hour = new Date().getHours();
  if (hour < 6) greeting = t("general.greeting.evening");
  else if (hour < 9) greeting = t("general.greeting.morning");
  else if (hour < 12) greeting = t("general.greeting.noon");
  else if (hour < 18) greeting = t("general.greeting.afternoon");
  return greeting;
};
