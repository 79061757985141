import { IconButton } from "@mui/material";
import Alert, { AlertProps } from "@mui/material/Alert";
import Collapse, { CollapseProps } from "@mui/material/Collapse";
import { useEffect, useRef, useState } from "react";
import MUIIcon from "../atomics/MUIIcon";

interface CollapsableAlertProps extends Omit<AlertProps, "children"> {
  alert?: string | false | null;
  closable?: boolean;
  CollapseProps?: CollapseProps;
}

const CollapsableAlert: React.FC<CollapsableAlertProps> = ({
  alert,
  closable,
  CollapseProps = {},
  action,
  ...props
}) => {
  const storedAlert = useRef<string>(alert || "");
  if (alert) storedAlert.current = alert;
  const [userClosed, setUserClosed] = useState(!!alert);

  useEffect(() => {
    setUserClosed(false);
  }, [alert]);

  const open = !!alert && !userClosed;
  return (
    <Collapse {...CollapseProps} in={open}>
      <Alert
        severity="error"
        action={
          action ||
          (closable && (
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setUserClosed(true);
              }}
            >
              <MUIIcon name="close" fontSize="inherit" />
            </IconButton>
          ))
        }
        {...props}
      >
        {storedAlert.current}
      </Alert>
    </Collapse>
  );
};

export default CollapsableAlert;
