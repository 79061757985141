import { createContext, useContext } from "react";
import { UseMeData } from "../apis/norskGassnettApiHooks";

export type IdentifiedContextType = {
  identity: UseMeData["current"];

  mutateIdentity: () => Promise<unknown>;
};

const IdentifiedContext = createContext<IdentifiedContextType>(
  {} as IdentifiedContextType
);

export const IdentifiedProvider = IdentifiedContext.Provider;

export const useIdentifiedContext = () => useContext(IdentifiedContext);
