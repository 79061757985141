import CssBaseline from "@mui/material/CssBaseline";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import { ThemeProvider } from "@mui/material";
import { theme } from "./styles/theme";
import "./style.css";
import "./i18n";
import { SWRConfig } from "swr";
import { fetcher } from "./lib/helpers/utils";
import { STORE_NAME } from "./constants";
import { norskGassnettApi } from "./lib/apis/norskGassnettApiHooks";
import { useStore } from "./store/store";
import { useEffect } from "react";

const localStore = localStorage.getItem(STORE_NAME);
const parsedStore = localStore ? JSON.parse(localStore) : null;
const token = parsedStore?.state?.auth?.token;
if (token) {
  norskGassnettApi.token = token;
}

const App = () => {
  const token = useStore((s) => s.auth.token);

  useEffect(() => {
    norskGassnettApi.token = token;
  }, [token]);

  return (
    <SWRConfig value={{ fetcher }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RouterProvider router={router} />
      </ThemeProvider>
    </SWRConfig>
  );
};

export default App;
