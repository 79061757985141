import { MuiTelInput, MuiTelInputProps } from "mui-tel-input";
import { FC } from "react";

const MUITelInput: FC<MuiTelInputProps> = ({ ...props }) => (
  <MuiTelInput
    langOfCountryName="no"
    defaultCountry="NO"
    preferredCountries={["NO"]}
    {...props}
  />
);

export default MUITelInput;
