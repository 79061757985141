export type PathParamsIdString = { id: string };

export type PathParamsIdNumber = { id: number };

export const resolveRoute = (...routeSegment: string[]) => {
  const trimmedRouteSegment = routeSegment.map((segment) =>
    segment.trim().replaceAll(/^\/|\/$/g, "")
  );
  return `/${trimmedRouteSegment.join("/")}`;
};

export const resolveV1Route = (...routeSegment: string[]) => {
  return resolveRoute(SEGMENT_V1, ...routeSegment);
};

export const replacePathVariables = (
  route: string,
  variables: Record<string, string | number>,
) => {
  let replacedRoute = route;

  for (const [key, value] of Object.entries(variables)) {
    replacedRoute = replacedRoute.replaceAll(`:${key}`, value.toString());
  }

  return replacedRoute;
};

/**
 * Route Segments
 */

export const SEGMENT_ROOT = "/";
export const SEGMENT_ID = "/:id";
export const SEGMENT_V1 = "v1";
export const SEGMENT_HEALTH = "health";
export const SEGMENT_IDENTITY = "identity";
export const SEGMENT_INVOICE = "invoice";
export const SEGMENT_METER_READING = "meter-reading";
export const SEGMENT_CONSUMPTION = "consumption";
export const SEGMENT_REAUTHENTICATE = "reauthenticate";
export const SEGMENT_ME = "me";
export const SEGMENT_CUSTOMER = "customer";
export const SEGMENT_SWITCH_CUSTOMER = "switch";
export const SEGMENT_START_LOGIN_FLOW = "start-login-flow";
export const SEGMENT_COMPLETE_LOGIN_FLOW = "complete-login-flow";
export const SEGMENT_START_UPDATE_FLOW = "start-update-flow";
export const SEGMENT_COMPLETE_UPDATE_FLOW = "complete-update-flow";
export const SEGMENT_RELOCATE = "relocate";
export const SEGMENT_CREDIT_MEMO = "credit-memo";
export const SEGMENT_GAS_PRICE = "gas-price";

/**
 * Route Paths
 */

export const PATH_HEALTH = resolveRoute(SEGMENT_HEALTH);
export const PATH_V1 = resolveRoute(SEGMENT_V1);
export const PATH_CUSTOMER = resolveRoute(SEGMENT_CUSTOMER);
export const PATH_IDENTITY = resolveRoute(SEGMENT_IDENTITY);
export const PATH_INVOICE = resolveRoute(SEGMENT_INVOICE);
export const PATH_METER_READING = resolveRoute(SEGMENT_METER_READING);
export const PATH_GAS_PRICE = resolveRoute(SEGMENT_GAS_PRICE);

export const PATH_START_UPDATE_CUSTMER_FLOW = resolveRoute(
  SEGMENT_START_UPDATE_FLOW,
);
export const PATH_COMPLETE_UPDATE_CUSTMER_FLOW = resolveRoute(
  SEGMENT_COMPLETE_UPDATE_FLOW,
);
export const PATH_RELOCATE = resolveRoute(SEGMENT_RELOCATE);
export const PATH_REAUTHENTICATE = resolveRoute(SEGMENT_REAUTHENTICATE);
export const PATH_ME = resolveRoute(SEGMENT_ME);
export const PATH_SWITCH_CUSTOMER = resolveRoute(
  SEGMENT_SWITCH_CUSTOMER,
);
export const PATH_START_LOGIN_CUSTOMER_FLOW = resolveRoute(
  SEGMENT_START_LOGIN_FLOW,
);
export const PATH_COMPLETE_LOGIN_CUSTOMER_FLOW = resolveRoute(
  SEGMENT_COMPLETE_LOGIN_FLOW,
);
export const PATH_LIST_INVOICES = resolveRoute();
export const PATH_READ_SALES_CREDIT_MEMO = resolveRoute(
  SEGMENT_CREDIT_MEMO,
  SEGMENT_ID,
);
export const PATH_READ_SALES_INVOICE = resolveRoute(SEGMENT_ID);
export const PATH_LIST_METER_READINGS = resolveRoute();
export const PATH_CREATE_METER_READINGS = resolveRoute();
export const PATH_LIST_CONSUMPTIONS = resolveRoute(SEGMENT_CONSUMPTION);
export const PATH_LIST_GAS_PRICES = resolveRoute();

/**
 * Absolute Routes
 */

export const ROUTE_HEALTH = PATH_HEALTH;
export const ROUTE_V1_START_UPDATE_CUSTMER_FLOW = resolveV1Route(
  SEGMENT_CUSTOMER,
  PATH_START_UPDATE_CUSTMER_FLOW,
);
export const ROUTE_V1_COMPLETE_UPDATE_CUSTMER_FLOW = resolveV1Route(
  SEGMENT_CUSTOMER,
  PATH_COMPLETE_UPDATE_CUSTMER_FLOW,
);
export const ROUTE_V1_RELOCATE = resolveV1Route(
  SEGMENT_CUSTOMER,
  PATH_RELOCATE,
);
export const ROUTE_V1_REAUTHENTICATE = resolveV1Route(
  SEGMENT_IDENTITY,
  PATH_REAUTHENTICATE,
);
export const ROUTE_V1_ME = resolveV1Route(SEGMENT_IDENTITY, PATH_ME);
export const ROUTE_V1_SWITCH_CUSTOMER = resolveV1Route(
  SEGMENT_IDENTITY,
  PATH_SWITCH_CUSTOMER,
);
export const ROUTE_V1_START_LOGIN_CUSTOMER_FLOW = resolveV1Route(
  SEGMENT_IDENTITY,
  PATH_START_LOGIN_CUSTOMER_FLOW,
);
export const ROUTE_V1_COMPLETE_LOGIN_CUSTOMER_FLOW = resolveV1Route(
  SEGMENT_IDENTITY,
  PATH_COMPLETE_LOGIN_CUSTOMER_FLOW,
);
export const ROUTE_V1_LIST_INVOICES = resolveV1Route(
  SEGMENT_INVOICE,
  PATH_LIST_INVOICES,
);
export const ROUTE_V1_READ_SALES_CREDIT_MEMO = resolveV1Route(
  SEGMENT_INVOICE,
  PATH_READ_SALES_CREDIT_MEMO,
);
export const ROUTE_V1_READ_SALES_INVOICE = resolveV1Route(
  SEGMENT_INVOICE,
  PATH_READ_SALES_INVOICE,
);
export const ROUTE_V1_LIST_METER_READINGS = resolveV1Route(
  SEGMENT_METER_READING,
  PATH_LIST_METER_READINGS,
);
export const ROUTE_V1_CREATE_METER_READINGS = resolveV1Route(
  SEGMENT_METER_READING,
  PATH_CREATE_METER_READINGS,
);
export const ROUTE_V1_LIST_CONSUMPTIONS = resolveV1Route(
  SEGMENT_METER_READING,
  PATH_LIST_CONSUMPTIONS,
);
export const ROUTE_V1_LIST_GAS_PRICES = resolveV1Route(
  SEGMENT_GAS_PRICE,
  PATH_LIST_GAS_PRICES,
);
