import React from "react";
import {
  Box,
  IconButton,
  Stack,
  StackProps as StackPropsT,
  Typography,
} from "@mui/material";
import MUIIcon from "./MUIIcon";

interface SubpageWrapperProps {
  title: string;
  backRef?: string;
  children: React.ReactNode;
  StackProps?: StackPropsT;
  specialAction?: React.ReactNode;
}

const SubpageWrapper: React.FC<SubpageWrapperProps> = ({
  title,
  backRef,
  children,
  StackProps = {},
  specialAction,
}) => {
  const { sx, ...props } = StackProps;
  return (
    <Stack sx={{ width: 1 }} alignItems="center">
      <Box
        sx={{
          px: 2,
          mb: 3,
          width: "100%",
          display: "grid",
          gridTemplateColumns: "1fr auto 1fr",
        }}
      >
        <Stack direction="column" justifyContent="center">
          {backRef && (
            <IconButton href={backRef} sx={{ width: "fit-content" }}>
              <MUIIcon name="arrow_back" color="primary" />
            </IconButton>
          )}
        </Stack>
        <Stack
          direction="column"
          justifyContent="center"
          sx={{ placeSelf: "center" }}
        >
          <Typography variant="h2" textAlign="center">
            {title}
          </Typography>
        </Stack>
        <Stack
          direction="column"
          justifyContent="center"
          sx={{ width: "fit-content", placeSelf: "flex-end" }}
        >
          {specialAction}
        </Stack>
      </Box>
      <Stack alignItems="center" {...props} sx={{ width: 1, ...sx }}>
        {children}
      </Stack>
    </Stack>
  );
};

export default SubpageWrapper;
