import React from "react";
import { Dayjs } from "dayjs";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { useControlledElement } from "../../hooks/useControlledElement";

export type DatePickerProps = {
  label?: string;
  value?: Dayjs | null;
  onChange?: (value: Dayjs | null) => void;
} & Omit<TextFieldProps, "onChange">;

const DatePicker: React.FC<DatePickerProps> = ({
  label,
  value: valueFromProps,
  onChange,
  ...props
}) => {
  const [value, setValue, controlled] = useControlledElement<Dayjs | null>(
    valueFromProps,
    null
  );

  const handleChange = (v: Dayjs | null) => {
    if (!controlled) setValue(v);
    onChange?.(v);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nb-NO">
      <MuiDatePicker
        label={label}
        value={value}
        inputFormat="DD.MM.YYYY"
        onChange={handleChange}
        renderInput={(p) => <TextField {...p} {...props} />}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
