import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useIdentity, UseIdentityProps } from "./useIdentity";
import useReauthentication, {
  UseAuthenticationProps,
} from "./useReauthentication";

export interface UseAuthorizationOrRedirectProps
  extends UseIdentityProps,
    UseAuthenticationProps {
  redirect: string;
}

export const useAuthorizationOrRedirect = ({
  token,
  redirect,
  setToken,
}: UseAuthorizationOrRedirectProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const identity = useIdentity({ token });

  useEffect(() => {
    if (!identity.isLoading && !identity.isIdentified) {
      navigate(redirect, { state: { from: location }, replace: true });
      setToken(null);
    }
  }, [identity, location, navigate, redirect, setToken, token]);

  useReauthentication({
    token: identity.isIdentified ? token : null,
    setToken,
  });

  return identity;
};
