import { useTranslation } from "react-i18next";
import TextParser, { TextParserProps } from "../atomics/TextParser";

export interface TranslatedLinkProps
  extends Omit<TextParserProps, "library" | "children"> {
  content: string;
  href?: string;
  linkProps?: TextParserProps["linkProps"];
}

/**
 * <TranslatedLink content='shared.login.forgot_password'/>
 *
 * takes
 * {
 *   ...
 *   "forgot_password": "Glemt passord? Klikk {{link}}.",
 *   "forgot_password_link": "#",
 *   "forgot_password_link_text": "her"
 * }
 *
 * from t18 and turns it into
 *
 * Glemt passord? Klikk her.
 *                       ^
 *                      link
 */
const TranslatedLink = ({ content, href, ...props }: TranslatedLinkProps) => {
  const { t } = useTranslation();

  return (
    <TextParser
      {...props}
      content={t(content)}
      library={{
        link: {
          link: href || t(`${content}_link`),
          text: t(`${content}_link_text`),
        },
      }}
    />
  );
};

export default TranslatedLink;
