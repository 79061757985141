import { Theme } from "@mui/material";
import { UNPARSED_PHONE_REGEX, PARSED_PHONE_REGEX } from "./regex";
import { UseMeData } from "../apis/norskGassnettApiHooks";
import { saveAs } from "file-saver";

const currencyCodeTable = {
  NOK: "kr",
};
export const numberToPrice = (n: number, currencyCode?: string) => {
  if (isNaN(n) || typeof n === "string") return "NaN";
  const price = n.toFixed(2).replace(".", ",");
  if (!currencyCode) return price;
  const suffix =
    currencyCodeTable[currencyCode as keyof typeof currencyCodeTable] ??
    ` ${currencyCode}`;
  return price + suffix;
};
export const getBorderRadius = (t: Theme) => ({
  xs: 0,
  // eslint-disable-next-line
  sm: (t?.components?.MuiPaper?.styleOverrides?.root as any)?.borderRadius,
});

export function trimDoubleSlashAtBeginningAndEnd(str: string) {
  return str.replace(/^\/\//, "/").replace(/\/\/$/, "/");
}

export function addSlashAtEnd(str: string) {
  if (str.endsWith("/")) return str;
  return str + "/";
}

export const fetcher = (input: RequestInfo | URL, init?: RequestInit) =>
  fetch(input, init).then((res) => res.json());

export type PathId = string | number;
export const replacePath = (
  path: string,
  replacers: Record<string, PathId>
) => {
  return Object.entries(replacers).reduce(
    (acc, [key, val]) => acc.replace(`:${key}`, (val || "null").toString()),
    path
  );
};

export const getAddressStringForCustomer = (
  customer: Pick<
    UseMeData["current"],
    "address2" | "visitingPostCode" | "visitingCity"
  >
) => {
  return `${customer.address2}, ${customer.visitingPostCode} ${customer.visitingCity}`;
};

export function dateFormat(date: Date = new Date()) {
  return date.toISOString().split("T")[0];
}

export function timeFormat(date: Date = new Date()) {
  return date.toISOString().split("T")[1].split(".")[0];
}

export function parsePhoneNumber(string: string) {
  if (!UNPARSED_PHONE_REGEX.test(string)) return null;
  string = string.replace(/^00/, "+");
  if (!string.startsWith("+")) string = "+47" + string;
  if (PARSED_PHONE_REGEX.test(string)) return string;
  return null;
}

interface DownloadFileProps {
  name: string;
  /** Base64 string */
  data: string;
  type?: string;
}

export const downloadFile = ({
  name,
  data,
  type = "application/pdf",
}: DownloadFileProps) => {
  const byteString = atob(data);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([int8Array], { type });
  saveAs(blob, name);
};

export const stringToColor = (string: string) => {
  let hash = 0;
  for (let i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  const wheel = (hash % 3600) / 10;
  const color = `hsl(${wheel}, 60%, 70%)`;

  return color;
};

export function capitalizeFirstLetter(text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function getMonthName(dateString: string, locale?: string) {
  const date = new Date(dateString);
  const monthName = new Intl.DateTimeFormat(locale, { month: "long" }).format(
    date
  );
  return capitalizeFirstLetter(monthName);
}
