import { Fetcher, FetcherHeader, RequesterOptions } from "./Fetcher.ts";

export type ApiBaseRequesterOptions = RequesterOptions;

export type IdLike = string | number;

export type ApiBaseConstructorOptions = {
  baseUrl?: string;
  token?: string;
};

export class ApiBase extends Fetcher {
  baseUrlPath = "";
  _baseUrl: string | undefined;
  _token: string | undefined;

  constructor({ baseUrl, token }: ApiBaseConstructorOptions = {}) {
    super();
    this.baseUrl = baseUrl;
    this._token = token;
  }

  get baseUrl() {
    return this._baseUrl;
  }

  set baseUrl(url: string | undefined | null) {
    if (url && url.length > 0) {
      const u = new URL(url);
      this._baseUrl = u.origin;
      if (u.pathname !== "/") {
        this.baseUrlPath = u.pathname;
      }
    } else {
      this._baseUrl = undefined;
    }
  }

  set token(token: string | undefined | null) {
    if (token && token.length > 0) {
      this._token = token;
    } else {
      this._token = undefined;
    }
  }

  get token() {
    return this._token;
  }

  get hasToken() {
    return !!this.token;
  }

  _parseUrl<R extends RequesterOptions = RequesterOptions>(
    input: string,
    options?: R,
  ) {
    const baseurl = options?.baseUrl ?? this.baseUrl ?? undefined;

    const url = new URL(input, baseurl);

    url.pathname = this.baseUrlPath + url.pathname;

    return url.toString();
  }

  addTokenToOptions<R extends RequesterOptions = RequesterOptions>(options: R) {
    if (this.token) {
      const bearerString = `Bearer ${this.token}`;

      if (options.headers) {
        if (options.headers instanceof Headers) {
          if (!options.headers.has(FetcherHeader.Authorization)) {
            options.headers.set(
              FetcherHeader.Authorization,
              bearerString,
            );
          }
        } else if (Array.isArray(options.headers)) {
          console.warn(
            "Automatic insertion of auth token is not supported when header is array. Use Object or Header instance instead.",
          );
        } else {
          // deno-lint-ignore ban-ts-comment
          // @ts-ignore
          if (!options.headers[FetcherHeader.Authorization]) {
            // deno-lint-ignore ban-ts-comment
            // @ts-ignore
            options.headers[FetcherHeader.Authorization] = bearerString;
          }
        }
      } else {
        const newHeaders = new Headers();
        newHeaders.set(FetcherHeader.Authorization, bearerString);
        options.headers = newHeaders;
      }
    }
  }

  requester<
    // deno-lint-ignore no-explicit-any
    ResponseBody = any,
    R extends RequesterOptions = RequesterOptions,
  >(
    input: string,
    options?: R,
  ) {
    const url = this._parseUrl<R>(input, options);

    const parsedOptions = { ...options } as R;
    this.addTokenToOptions(parsedOptions);

    return super.requester<ResponseBody, R>(url, parsedOptions);
  }
}
