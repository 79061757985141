import {
  CompleteLoginCustomerFlowRequestBody,
  CompleteLoginCustomerFlowResponseBody,
  MeResponseBody,
  ReauthenticateResponseBody,
  StartLoginCustomerFlowRequestBody,
  StartLoginCustomerFlowResponseBody,
  SwitchCustomerRequestBody,
  SwitchCustomerResponseBody,
} from "../../identity/structs.ts";
import { EmptyResponse, ResponseWrapper } from "../helpers/response.ts";
import {
  ListInvoicesResponseBody,
  ListInvoicesSearchParams,
  ReadSalesCreditMemoPathParams,
  ReadSalesCreditMemoResponseBody,
  ReadSalesCreditMemoSearchParams,
  ReadSalesInvoicePathParams,
  ReadSalesInvoiceResponseBody,
  ReadSalesInvoiceSearchParams,
} from "../../invoice/structs.ts";
import { ApiBase, ApiBaseConstructorOptions } from "./ApiBase.ts";
import { RequesterOptions, RequesterParameters } from "./Fetcher.ts";
import {
  CreateMeterReadingRequestBody,
  CreateMeterReadingResponseBody,
  ListConsumptionsRequestSearchParams,
  ListConsumptionsResponseBody,
  ListMeterReadingsRequestSearchParams,
  ListMeterReadingsResponseBody,
} from "../../meter_readings/structs.ts";
import {
  CompleteUpdateCustomerFlowRequestBody,
  CompleteUpdateCustomerFlowResponseBody,
  RelocateCustomerRequestBody,
  RelocateCustomerResponseBody,
  StartUpdateCustomerFlowRequestBody,
  StartUpdateCustomerFlowResponseBody,
} from "../../customer/structs.ts";
import {
  replacePathVariables,
  ROUTE_HEALTH,
  ROUTE_V1_COMPLETE_LOGIN_CUSTOMER_FLOW,
  ROUTE_V1_COMPLETE_UPDATE_CUSTMER_FLOW,
  ROUTE_V1_CREATE_METER_READINGS,
  ROUTE_V1_LIST_CONSUMPTIONS,
  ROUTE_V1_LIST_GAS_PRICES,
  ROUTE_V1_LIST_INVOICES,
  ROUTE_V1_LIST_METER_READINGS,
  ROUTE_V1_ME,
  ROUTE_V1_READ_SALES_CREDIT_MEMO,
  ROUTE_V1_READ_SALES_INVOICE,
  ROUTE_V1_REAUTHENTICATE,
  ROUTE_V1_RELOCATE,
  ROUTE_V1_START_LOGIN_CUSTOMER_FLOW,
  ROUTE_V1_START_UPDATE_CUSTMER_FLOW,
  ROUTE_V1_SWITCH_CUSTOMER,
} from "../helpers/route.ts";
import {
  ListGasPricesRequestSearchParams,
  ListGasPricesResponseBody,
} from "../../gas_prices/structs.ts";

export type RequestOptions<
  Parameters extends RequesterParameters = RequesterParameters,
> = {
  options?: RequesterOptions<Parameters>;
};

export type SwitchCustomerOptions = RequestOptions & {
  body: SwitchCustomerRequestBody;
};

export type StartLoginCustomerFlowOptions = RequestOptions & {
  body: StartLoginCustomerFlowRequestBody;
};

export type CompleteLoginCustomerFlowOptions = RequestOptions & {
  body: CompleteLoginCustomerFlowRequestBody;
};

export type ReauthenticateOptions = RequestOptions;
export type MeOptions = RequestOptions;

export type StartUpdateCustomerFlowOptions = RequestOptions & {
  body: StartUpdateCustomerFlowRequestBody;
};
export type CompleteUpdateCustomerFlowOptions = RequestOptions & {
  body: CompleteUpdateCustomerFlowRequestBody;
};
export type RelocateCustomerOptions = RequestOptions & {
  body: RelocateCustomerRequestBody;
};

export type ListInvoicesOptions = RequestOptions<ListInvoicesSearchParams>;

export type ReadSalesInvoiceOptions =
  & RequestOptions<ReadSalesInvoiceSearchParams>
  & {
    salesInvoiceId: ReadSalesInvoicePathParams["id"];
  };

export type ReadSalesCreditMemoOptions =
  & RequestOptions<ReadSalesCreditMemoSearchParams>
  & {
    salesCreditMemoId: ReadSalesCreditMemoPathParams["id"];
  };

export type CreateMeterReadingOptions =
  & RequestOptions
  & {
    body: CreateMeterReadingRequestBody;
  };

export type ListMeterReadingsOptions = RequestOptions<
  ListMeterReadingsRequestSearchParams
>;

export type ListConsumptionsOptions = RequestOptions<
  ListConsumptionsRequestSearchParams
>;

export type ListGasPricesOptions = RequestOptions<
  ListGasPricesRequestSearchParams
>;

export type NorskGassnettApiConstructorOptions = ApiBaseConstructorOptions;
export class NorskGassnettApi extends ApiBase {
  _loginInterval: number | undefined;
  constructor(options: NorskGassnettApiConstructorOptions) {
    super(options);
  }
  get token(): string | null | undefined {
    return super.token;
  }
  set token(token: string | null | undefined) {
    super.token = token;
  }

  async startAuthInterval(
    { onError, onSuccess }: {
      onSuccess?: (data: ReauthenticateResponseBody) => void;
      onError?: () => void;
    } = {},
  ) {
    try {
      const { data } = await this.reauthenticate();

      const delay = data.expiresIn - 60 * 10;

      this.token = data.token;
      onSuccess?.(data);

      console.info(
        `Updated token, expires in ${data.expiresIn} seconds, updating again in ${delay} seconds`,
      );

      this.stopAuthInterval();
      this._loginInterval = setTimeout(
        () => this.startAuthInterval.bind(this)({ onError, onSuccess }),
        delay * 1000,
      );
    } catch (e) {
      console.error("Could not update NG credentials:", e.message, e);
      this.stopAuthInterval();

      onError?.();
      // if (onError) {
      //   onError();
      // } else {
      //   this._loginInterval = setTimeout(
      //     this.startAuthInterval.bind(this),
      //     60 * 1000,
      //   );
      // }
    }
  }

  stopAuthInterval() {
    if (this._loginInterval) {
      clearTimeout(this._loginInterval);
    }
  }

  disconnect() {
    this.stopAuthInterval();
    this.token = undefined;
  }

  /**
   * Health
   */

  health() {
    return this.get<EmptyResponse>(
      ROUTE_HEALTH,
    );
  }

  async isHealthy() {
    try {
      await this.health();
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  /**
   * Customer
   */

  startUpdateCustomerFlow({ body, options }: StartUpdateCustomerFlowOptions) {
    return this.post<ResponseWrapper<StartUpdateCustomerFlowResponseBody>>(
      ROUTE_V1_START_UPDATE_CUSTMER_FLOW,
      JSON.stringify(body),
      options,
    );
  }

  completeUpdateCustomerFlow(
    { body, options }: CompleteUpdateCustomerFlowOptions,
  ) {
    return this.post<ResponseWrapper<CompleteUpdateCustomerFlowResponseBody>>(
      ROUTE_V1_COMPLETE_UPDATE_CUSTMER_FLOW,
      JSON.stringify(body),
      options,
    );
  }

  relocateCustomer(
    { body, options }: RelocateCustomerOptions,
  ) {
    return this.post<ResponseWrapper<RelocateCustomerResponseBody>>(
      ROUTE_V1_RELOCATE,
      JSON.stringify(body),
      options,
    );
  }

  /**
   * GasPrice
   */

  listGasPrices(options?: ListGasPricesOptions) {
    return this.get<ResponseWrapper<ListGasPricesResponseBody>>(
      ROUTE_V1_LIST_GAS_PRICES,
      options?.options,
    );
  }

  /**
   * Identity
   */

  reauthenticate(options?: ReauthenticateOptions) {
    return this.get<ResponseWrapper<ReauthenticateResponseBody>>(
      ROUTE_V1_REAUTHENTICATE,
      options?.options,
    );
  }

  me(options?: MeOptions) {
    return this.get<ResponseWrapper<MeResponseBody>>(
      ROUTE_V1_ME,
      options?.options,
    );
  }

  switchCustomer({ body, options }: SwitchCustomerOptions) {
    return this.post<ResponseWrapper<SwitchCustomerResponseBody>>(
      ROUTE_V1_SWITCH_CUSTOMER,
      JSON.stringify(body),
      options,
    );
  }

  startLoginCustomerFlow({ body, options }: StartLoginCustomerFlowOptions) {
    return this.post<ResponseWrapper<StartLoginCustomerFlowResponseBody>>(
      ROUTE_V1_START_LOGIN_CUSTOMER_FLOW,
      JSON.stringify(body),
      options,
    );
  }

  completeLoginCustomerFlow(
    { body, options }: CompleteLoginCustomerFlowOptions,
  ) {
    return this.post<ResponseWrapper<CompleteLoginCustomerFlowResponseBody>>(
      ROUTE_V1_COMPLETE_LOGIN_CUSTOMER_FLOW,
      JSON.stringify(body),
      options,
    );
  }

  /**
   * Invoice
   */

  listInvoices(options?: ListInvoicesOptions) {
    return this.get<ResponseWrapper<ListInvoicesResponseBody>>(
      ROUTE_V1_LIST_INVOICES,
      options?.options,
    );
  }

  readSalesInvoice(
    { salesInvoiceId, options }: ReadSalesInvoiceOptions,
  ) {
    return this.get<ResponseWrapper<ReadSalesInvoiceResponseBody>>(
      replacePathVariables(ROUTE_V1_READ_SALES_INVOICE, { id: salesInvoiceId }),
      options,
    );
  }

  readSalesCreditMemo(
    { salesCreditMemoId, options }: ReadSalesCreditMemoOptions,
  ) {
    return this.get<ResponseWrapper<ReadSalesCreditMemoResponseBody>>(
      replacePathVariables(ROUTE_V1_READ_SALES_CREDIT_MEMO, {
        id: salesCreditMemoId,
      }),
      options,
    );
  }

  /**
   * MeterReading
   */

  createMeterReading({ body, options }: CreateMeterReadingOptions) {
    return this.post<ResponseWrapper<CreateMeterReadingResponseBody>>(
      ROUTE_V1_CREATE_METER_READINGS,
      JSON.stringify(body),
      options,
    );
  }

  listMeterReadings(options?: ListMeterReadingsOptions) {
    return this.get<ResponseWrapper<ListMeterReadingsResponseBody>>(
      ROUTE_V1_LIST_METER_READINGS,
      options?.options,
    );
  }

  listConsumptions(options?: ListConsumptionsOptions) {
    return this.get<ResponseWrapper<ListConsumptionsResponseBody>>(
      ROUTE_V1_LIST_CONSUMPTIONS,
      options?.options,
    );
  }
}
