import React from "react";
import { Badge, Box, Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { PATH as PATH_ROOT } from "../../../routes/root";
import { PATH as PATH_USAGE } from "../../../routes/__authenticated/usage";
import { PATH as PATH_METER_READING } from "../../../routes/__authenticated/meter-reading";
import {
  getDefaultInvoiceDateFrom,
  PATH as PATH_INVOICES,
} from "../../../routes/__authenticated/invoices";
import MUIIcon from "../atomics/MUIIcon";
import { useLocation } from "react-router-dom";
import { useListInvoicesFetcher } from "../../apis/norskGassnettApiHooks";

interface HomeHeaderLinkProps {
  icon: React.ReactNode;
  title: string;
  link: string;
  selected: boolean;
}

const HomeHeaderLink: React.FC<HomeHeaderLinkProps> = ({
  icon,
  title,
  link,
  selected,
}) => {
  const size = 90;
  return (
    <Button
      href={link}
      variant={selected ? "contained" : "text"}
      sx={{
        p: 1,
        minWidth: "min-content",
        width: size * 1.75,
        height: size,
        borderRadius: 0,
      }}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        gap={1}
        sx={{ width: "100%", height: "100%" }}
      >
        {icon}
        <Typography variant="h6" fontSize={{ xs: "0.8rem", sm: "0.9rem" }}>
          {title}
        </Typography>
      </Stack>
    </Button>
  );
};

const HomeHeader: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const invoices = useListInvoicesFetcher({
    options: { parameters: { invoiceDateFrom: getDefaultInvoiceDateFrom() } },
  });

  const openInvoices =
    invoices.data?.salesInvoices.filter((invoice) => invoice.status === "Open")
      .length || 0;

  const links = [
    {
      icon: <MUIIcon name="home" filled />,
      title: t("home.title"),
      link: PATH_ROOT,
    },
    {
      icon: <MUIIcon name="monitoring" />,
      title: t("usage.title"),
      link: PATH_USAGE,
    },
    {
      icon: <MUIIcon name="gas_meter" filled />,
      title: t("meter.title"),
      link: PATH_METER_READING,
    },
    {
      icon: (
        <Badge badgeContent={openInvoices} color="error" overlap="circular">
          <MUIIcon name="description" />
        </Badge>
      ),
      title: t("invoice.title"),
      link: PATH_INVOICES,
    },
    // {
    //   icon: (
    //     <Badge badgeContent={openInvoices} color="error" overlap="circular">
    //       <MUIIcon name="error" />
    //     </Badge>
    //   ),
    //   title: t("info.title"),
    //   link: PATH_INFO,
    // },
  ];
  return (
    <Box>
      <Stack
        sx={{
          backgroundImage: `url(/assets/header-bg.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          width: "100%",
          height: { xs: 120, sm: 200 },
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h1"
          color="primary.contrastText"
          fontSize={(t) => ({ xs: "1.4rem", sm: t.typography.h1.fontSize })}
        >
          {t("header.title")}
        </Typography>
      </Stack>
      <Stack
        component="header"
        justifyContent="center"
        direction="row"
        sx={{ bgcolor: "background.paper", overflowX: "auto" }}
      >
        {links.map((l, i) => (
          <HomeHeaderLink
            key={i}
            {...l}
            selected={location.pathname.split("/")[1] === l.link.substring(1)}
          />
        ))}
      </Stack>
    </Box>
  );
};

export default HomeHeader;
