import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { NavigateFunction } from "react-router-dom";
import { IS_DEV } from "../../../variables";

export interface ErrorPageProps {
  navigate: NavigateFunction;
  error: AnyError;
  backpath: string;
  homepath: string;
}

const ErrorPage: React.FC<ErrorPageProps> = ({
  navigate,
  error,
  backpath,
  homepath,
}) => {
  const { t } = useTranslation();
  console.error(error);

  if (error?.status === 404) {
    return (
      <Box
        sx={{
          pt: 10,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <Typography variant="h1">404</Typography>
        <Typography>{t("error.404.body")}</Typography>
        <div>
          <Button onClick={() => navigate(backpath)}>
            {t("error.404.btn_go_back")}
          </Button>
          <Button onClick={() => navigate(homepath)}>
            {t("error.404.btn_go_home")}
          </Button>
        </div>
      </Box>
    );
  }

  const expandedError = [];
  if (IS_DEV && error instanceof Error) {
    const clear = error.stack?.replace(/:\d+:\d+$/gm, "") || "";
    const stack: string[] = clear.match(/^[^@]+/gm) ?? [];
    const files = [
      ...clear.matchAll(/^[^@]+@[^@]+@.*frontend\/(.+)\?v=d[a-z0-9]+$/gm),
    ].map((a) => a[1] ?? "-error-");
    const longest = Math.max(...stack.map((s) => s.length));
    const stackLength = stack.length;
    const stackLengthNumberLength = (stackLength - 1).toString().length;
    for (let i = 0; i < stackLength; i++) {
      const callback = stack[i];
      const file = files[i];
      expandedError.push(
        `[${i
          .toString()
          .padStart(stackLengthNumberLength, " ")}]: ${callback.padEnd(
          longest,
          "."
        )} > ${file}`
      );
    }
  }
  return (
    <div id="error-page">
      <h1>{t("error.general.header")}</h1>
      <p>{t("error.general.body")}</p>
      <p>
        <i>{error?.statusText || error?.message}</i>
      </p>
      {expandedError.length && (
        <code>
          {expandedError.map((s, i) => (
            <div key={i} style={{ whiteSpace: "nowrap" }}>
              {s}
            </div>
          ))}
        </code>
      )}
    </div>
  );
};

export default ErrorPage;
