import { Stack, StackProps, Paper, Box, IconButton } from "@mui/material";
import MUIIcon from "./MUIIcon";
import Logo from "./Logo";

interface AnonymousWrapperProps extends StackProps {
  noLogo?: boolean;
  backFunction?: (() => void) | false;
  children: React.ReactNode;
}
const AnonymousWrapper: React.FC<AnonymousWrapperProps> = ({
  noLogo,
  backFunction,
  children,
  ...props
}) => {
  return (
    <Paper
      sx={{
        width: { xs: "100%", sm: 400 },
        p: 3,
      }}
    >
      <Stack
        mt={1}
        direction="column"
        alignItems="center"
        spacing={2}
        {...props}
      >
        <Box
          sx={{
            mb: 3,
            width: "100%",
            display: "grid",
            gridTemplateColumns: "1fr auto 1fr",
          }}
        >
          <IconButton
            sx={{ visibility: backFunction ? "visible" : "hidden" }}
            onClick={backFunction || undefined}
          >
            <MUIIcon
              name="arrow_back"
              color="primary"
              sx={{ fontSize: "2rem" }}
            />
          </IconButton>
          {!noLogo && (
            <Logo
              sx={{ width: "100%", maxWidth: "20rem", placeSelf: "center" }}
            />
          )}
          {/* Used for spacing, isnt actually displayed */}
          <IconButton sx={{ visibility: "hidden" }}>
            <MUIIcon sx={{ fontSize: "2rem" }} />
          </IconButton>
        </Box>
        {children}
      </Stack>
    </Paper>
  );
};

export default AnonymousWrapper;
