import { Box, BoxProps, Link } from "@mui/material";

export interface LogoProps extends BoxProps {
  variant?: "color" | "white";
  href?: string;
}

const Logo = ({ variant, href, ...props }: LogoProps) => {
  const logo = (
    <Box
      component="img"
      src={variant === "white" ? "/assets/logo-white.svg" : "/assets/logo.svg"}
      alt="logo"
      loading="lazy"
      href={href}
      {...props}
    />
  );
  return !href ? (
    logo
  ) : (
    // Padding added to offset inherit unremovable link padding
    <Link href={href} pt="0.4rem">
      {logo}
    </Link>
  );
};

export default Logo;
