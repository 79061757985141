/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import useSWR, { Key, Middleware, SWRResponse, SWRConfiguration } from "swr";
import useSWRMutation, {
  SWRMutationConfiguration,
  SWRMutationResponse,
} from "swr/mutation";

import { NorskGassnettApi } from "../../../../backend/src/lib/apis/NorskGassnettApi";

export const norskGassnettApi = new NorskGassnettApi({
  baseUrl: import.meta.env.VITE_API_URL,
});

export const disableCache: Middleware = (useSWRNext) => {
  return (key, fetcher, config) => {
    const swr = useSWRNext(key, fetcher, config);
    const { data, isValidating } = swr;
    return Object.assign({}, swr, {
      data: isValidating ? undefined : data,
    });
  };
};

type AsyncFunction = (...args: any) => Promise<any>;
export type GetDataT<T extends AsyncFunction> = Awaited<ReturnType<T>>["data"];
export type GetArgT<T extends (...args: any) => any> = Parameters<T>[0];
export type FetcherConfigExtended<T> = T & {
  /**
   * If true, the fetcher will not be called until this becomes false, until then the data will be undefined.
   */
  skip?: boolean;
  /**
   * If true, cache will be disabled for this fetcher (previous cache for the used key will be deleted and no new one will be stored).
   */
  disableCache?: boolean;
  /**
   * Overrides the swr cache key
   */
  overrideKey?: Key;
};
export type MutationConfigExtended<T> = T & {
  /**
   * Overrides the swr cache key
   */
  overrideKey?: Key;
};

export type UseHealthData = GetDataT<NorskGassnettApi["health"]>;
export type UseHealthArg = GetArgT<NorskGassnettApi["health"]>;
export type UseHealthKey = [string, UseHealthArg];
export type UseHealthFetcherConfig = FetcherConfigExtended<
  SWRConfiguration<UseHealthData>
>;
export type UseHealthFetcherResponse = SWRResponse<UseHealthData, any>;
export type UseHealthMutationConfig = MutationConfigExtended<
  SWRMutationConfiguration<UseHealthData, any, UseHealthArg, string>
>;
export type UseHealthMutationResponse = SWRMutationResponse<
  UseHealthData,
  any,
  UseHealthArg
>;
/**
 * useHealthFetcher
 * See https://swr.vercel.app/docs/data-fetching
 */
export const useHealthFetcher = (
  arg?: UseHealthArg,
  config?: UseHealthFetcherConfig
) => {
  const key = config?.skip
    ? null
    : config?.overrideKey
    ? config.overrideKey
    : ["health", arg];

  const parsedConfig = { ...config };

  if (config?.disableCache) {
    if (!parsedConfig.use?.length) {
      parsedConfig.use = [disableCache];
    } else {
      parsedConfig.use.push(disableCache);
    }
  }

  return useSWR(
    key,
    async ([_, arg]) => {
      const { data } = await norskGassnettApi.health(arg);
      return data;
    },
    parsedConfig
  ) as UseHealthFetcherResponse;
};
/**
 * useHealthMutation
 * See https://swr.vercel.app/docs/mutation
 */
export const useHealthMutation = (config?: UseHealthMutationConfig) => {
  const key = config?.overrideKey ? config.overrideKey : ["health"];

  return useSWRMutation(
    key,
    async (_, { arg }) => {
      const { data } = await norskGassnettApi.health(arg);
      return data;
    },
    config
  ) as UseHealthMutationResponse;
};

export type UseStartUpdateCustomerFlowData = GetDataT<
  NorskGassnettApi["startUpdateCustomerFlow"]
>;
export type UseStartUpdateCustomerFlowArg = GetArgT<
  NorskGassnettApi["startUpdateCustomerFlow"]
>;
export type UseStartUpdateCustomerFlowKey = [
  string,
  UseStartUpdateCustomerFlowArg
];
export type UseStartUpdateCustomerFlowFetcherConfig = FetcherConfigExtended<
  SWRConfiguration<UseStartUpdateCustomerFlowData>
>;
export type UseStartUpdateCustomerFlowFetcherResponse = SWRResponse<
  UseStartUpdateCustomerFlowData,
  any
>;
export type UseStartUpdateCustomerFlowMutationConfig = MutationConfigExtended<
  SWRMutationConfiguration<
    UseStartUpdateCustomerFlowData,
    any,
    UseStartUpdateCustomerFlowArg,
    string
  >
>;
export type UseStartUpdateCustomerFlowMutationResponse = SWRMutationResponse<
  UseStartUpdateCustomerFlowData,
  any,
  UseStartUpdateCustomerFlowArg
>;
/**
 * useStartUpdateCustomerFlowFetcher
 * See https://swr.vercel.app/docs/data-fetching
 */
export const useStartUpdateCustomerFlowFetcher = (
  arg?: UseStartUpdateCustomerFlowArg,
  config?: UseStartUpdateCustomerFlowFetcherConfig
) => {
  const key = config?.skip
    ? null
    : config?.overrideKey
    ? config.overrideKey
    : ["startUpdateCustomerFlow", arg];

  const parsedConfig = { ...config };

  if (config?.disableCache) {
    if (!parsedConfig.use?.length) {
      parsedConfig.use = [disableCache];
    } else {
      parsedConfig.use.push(disableCache);
    }
  }

  return useSWR(
    key,
    async ([_, arg]) => {
      const { data } = await norskGassnettApi.startUpdateCustomerFlow(arg);
      return data;
    },
    parsedConfig
  ) as UseStartUpdateCustomerFlowFetcherResponse;
};
/**
 * useStartUpdateCustomerFlowMutation
 * See https://swr.vercel.app/docs/mutation
 */
export const useStartUpdateCustomerFlowMutation = (
  config?: UseStartUpdateCustomerFlowMutationConfig
) => {
  const key = config?.overrideKey
    ? config.overrideKey
    : ["startUpdateCustomerFlow"];

  return useSWRMutation(
    key,
    async (_, { arg }) => {
      const { data } = await norskGassnettApi.startUpdateCustomerFlow(arg);
      return data;
    },
    config
  ) as UseStartUpdateCustomerFlowMutationResponse;
};

export type UseCompleteUpdateCustomerFlowData = GetDataT<
  NorskGassnettApi["completeUpdateCustomerFlow"]
>;
export type UseCompleteUpdateCustomerFlowArg = GetArgT<
  NorskGassnettApi["completeUpdateCustomerFlow"]
>;
export type UseCompleteUpdateCustomerFlowKey = [
  string,
  UseCompleteUpdateCustomerFlowArg
];
export type UseCompleteUpdateCustomerFlowFetcherConfig = FetcherConfigExtended<
  SWRConfiguration<UseCompleteUpdateCustomerFlowData>
>;
export type UseCompleteUpdateCustomerFlowFetcherResponse = SWRResponse<
  UseCompleteUpdateCustomerFlowData,
  any
>;
export type UseCompleteUpdateCustomerFlowMutationConfig =
  MutationConfigExtended<
    SWRMutationConfiguration<
      UseCompleteUpdateCustomerFlowData,
      any,
      UseCompleteUpdateCustomerFlowArg,
      string
    >
  >;
export type UseCompleteUpdateCustomerFlowMutationResponse = SWRMutationResponse<
  UseCompleteUpdateCustomerFlowData,
  any,
  UseCompleteUpdateCustomerFlowArg
>;
/**
 * useCompleteUpdateCustomerFlowFetcher
 * See https://swr.vercel.app/docs/data-fetching
 */
export const useCompleteUpdateCustomerFlowFetcher = (
  arg?: UseCompleteUpdateCustomerFlowArg,
  config?: UseCompleteUpdateCustomerFlowFetcherConfig
) => {
  const key = config?.skip
    ? null
    : config?.overrideKey
    ? config.overrideKey
    : ["completeUpdateCustomerFlow", arg];

  const parsedConfig = { ...config };

  if (config?.disableCache) {
    if (!parsedConfig.use?.length) {
      parsedConfig.use = [disableCache];
    } else {
      parsedConfig.use.push(disableCache);
    }
  }

  return useSWR(
    key,
    async ([_, arg]) => {
      const { data } = await norskGassnettApi.completeUpdateCustomerFlow(arg);
      return data;
    },
    parsedConfig
  ) as UseCompleteUpdateCustomerFlowFetcherResponse;
};
/**
 * useCompleteUpdateCustomerFlowMutation
 * See https://swr.vercel.app/docs/mutation
 */
export const useCompleteUpdateCustomerFlowMutation = (
  config?: UseCompleteUpdateCustomerFlowMutationConfig
) => {
  const key = config?.overrideKey
    ? config.overrideKey
    : ["completeUpdateCustomerFlow"];

  return useSWRMutation(
    key,
    async (_, { arg }) => {
      const { data } = await norskGassnettApi.completeUpdateCustomerFlow(arg);
      return data;
    },
    config
  ) as UseCompleteUpdateCustomerFlowMutationResponse;
};

export type UseRelocateCustomerData = GetDataT<
  NorskGassnettApi["relocateCustomer"]
>;
export type UseRelocateCustomerArg = GetArgT<
  NorskGassnettApi["relocateCustomer"]
>;
export type UseRelocateCustomerKey = [string, UseRelocateCustomerArg];
export type UseRelocateCustomerFetcherConfig = FetcherConfigExtended<
  SWRConfiguration<UseRelocateCustomerData>
>;
export type UseRelocateCustomerFetcherResponse = SWRResponse<
  UseRelocateCustomerData,
  any
>;
export type UseRelocateCustomerMutationConfig = MutationConfigExtended<
  SWRMutationConfiguration<
    UseRelocateCustomerData,
    any,
    UseRelocateCustomerArg,
    string
  >
>;
export type UseRelocateCustomerMutationResponse = SWRMutationResponse<
  UseRelocateCustomerData,
  any,
  UseRelocateCustomerArg
>;
/**
 * useRelocateCustomerFetcher
 * See https://swr.vercel.app/docs/data-fetching
 */
export const useRelocateCustomerFetcher = (
  arg?: UseRelocateCustomerArg,
  config?: UseRelocateCustomerFetcherConfig
) => {
  const key = config?.skip
    ? null
    : config?.overrideKey
    ? config.overrideKey
    : ["relocateCustomer", arg];

  const parsedConfig = { ...config };

  if (config?.disableCache) {
    if (!parsedConfig.use?.length) {
      parsedConfig.use = [disableCache];
    } else {
      parsedConfig.use.push(disableCache);
    }
  }

  return useSWR(
    key,
    async ([_, arg]) => {
      const { data } = await norskGassnettApi.relocateCustomer(arg);
      return data;
    },
    parsedConfig
  ) as UseRelocateCustomerFetcherResponse;
};
/**
 * useRelocateCustomerMutation
 * See https://swr.vercel.app/docs/mutation
 */
export const useRelocateCustomerMutation = (
  config?: UseRelocateCustomerMutationConfig
) => {
  const key = config?.overrideKey ? config.overrideKey : ["relocateCustomer"];

  return useSWRMutation(
    key,
    async (_, { arg }) => {
      const { data } = await norskGassnettApi.relocateCustomer(arg);
      return data;
    },
    config
  ) as UseRelocateCustomerMutationResponse;
};

export type UseListGasPricesData = GetDataT<NorskGassnettApi["listGasPrices"]>;
export type UseListGasPricesArg = GetArgT<NorskGassnettApi["listGasPrices"]>;
export type UseListGasPricesKey = [string, UseListGasPricesArg];
export type UseListGasPricesFetcherConfig = FetcherConfigExtended<
  SWRConfiguration<UseListGasPricesData>
>;
export type UseListGasPricesFetcherResponse = SWRResponse<
  UseListGasPricesData,
  any
>;
export type UseListGasPricesMutationConfig = MutationConfigExtended<
  SWRMutationConfiguration<
    UseListGasPricesData,
    any,
    UseListGasPricesArg,
    string
  >
>;
export type UseListGasPricesMutationResponse = SWRMutationResponse<
  UseListGasPricesData,
  any,
  UseListGasPricesArg
>;
/**
 * useListGasPricesFetcher
 * See https://swr.vercel.app/docs/data-fetching
 */
export const useListGasPricesFetcher = (
  arg?: UseListGasPricesArg,
  config?: UseListGasPricesFetcherConfig
) => {
  const key = config?.skip
    ? null
    : config?.overrideKey
    ? config.overrideKey
    : ["listGasPrices", arg];

  const parsedConfig = { ...config };

  if (config?.disableCache) {
    if (!parsedConfig.use?.length) {
      parsedConfig.use = [disableCache];
    } else {
      parsedConfig.use.push(disableCache);
    }
  }

  return useSWR(
    key,
    async ([_, arg]) => {
      const { data } = await norskGassnettApi.listGasPrices(arg);
      return data;
    },
    parsedConfig
  ) as UseListGasPricesFetcherResponse;
};
/**
 * useListGasPricesMutation
 * See https://swr.vercel.app/docs/mutation
 */
export const useListGasPricesMutation = (
  config?: UseListGasPricesMutationConfig
) => {
  const key = config?.overrideKey ? config.overrideKey : ["listGasPrices"];

  return useSWRMutation(
    key,
    async (_, { arg }) => {
      const { data } = await norskGassnettApi.listGasPrices(arg);
      return data;
    },
    config
  ) as UseListGasPricesMutationResponse;
};

export type UseReauthenticateData = GetDataT<
  NorskGassnettApi["reauthenticate"]
>;
export type UseReauthenticateArg = GetArgT<NorskGassnettApi["reauthenticate"]>;
export type UseReauthenticateKey = [string, UseReauthenticateArg];
export type UseReauthenticateFetcherConfig = FetcherConfigExtended<
  SWRConfiguration<UseReauthenticateData>
>;
export type UseReauthenticateFetcherResponse = SWRResponse<
  UseReauthenticateData,
  any
>;
export type UseReauthenticateMutationConfig = MutationConfigExtended<
  SWRMutationConfiguration<
    UseReauthenticateData,
    any,
    UseReauthenticateArg,
    string
  >
>;
export type UseReauthenticateMutationResponse = SWRMutationResponse<
  UseReauthenticateData,
  any,
  UseReauthenticateArg
>;
/**
 * useReauthenticateFetcher
 * See https://swr.vercel.app/docs/data-fetching
 */
export const useReauthenticateFetcher = (
  arg?: UseReauthenticateArg,
  config?: UseReauthenticateFetcherConfig
) => {
  const key = config?.skip
    ? null
    : config?.overrideKey
    ? config.overrideKey
    : ["reauthenticate", arg];

  const parsedConfig = { ...config };

  if (config?.disableCache) {
    if (!parsedConfig.use?.length) {
      parsedConfig.use = [disableCache];
    } else {
      parsedConfig.use.push(disableCache);
    }
  }

  return useSWR(
    key,
    async ([_, arg]) => {
      const { data } = await norskGassnettApi.reauthenticate(arg);
      return data;
    },
    parsedConfig
  ) as UseReauthenticateFetcherResponse;
};
/**
 * useReauthenticateMutation
 * See https://swr.vercel.app/docs/mutation
 */
export const useReauthenticateMutation = (
  config?: UseReauthenticateMutationConfig
) => {
  const key = config?.overrideKey ? config.overrideKey : ["reauthenticate"];

  return useSWRMutation(
    key,
    async (_, { arg }) => {
      const { data } = await norskGassnettApi.reauthenticate(arg);
      return data;
    },
    config
  ) as UseReauthenticateMutationResponse;
};

export type UseMeData = GetDataT<NorskGassnettApi["me"]>;
export type UseMeArg = GetArgT<NorskGassnettApi["me"]>;
export type UseMeKey = [string, UseMeArg];
export type UseMeFetcherConfig = FetcherConfigExtended<
  SWRConfiguration<UseMeData>
>;
export type UseMeFetcherResponse = SWRResponse<UseMeData, any>;
export type UseMeMutationConfig = MutationConfigExtended<
  SWRMutationConfiguration<UseMeData, any, UseMeArg, string>
>;
export type UseMeMutationResponse = SWRMutationResponse<
  UseMeData,
  any,
  UseMeArg
>;
/**
 * useMeFetcher
 * See https://swr.vercel.app/docs/data-fetching
 */
export const useMeFetcher = (arg?: UseMeArg, config?: UseMeFetcherConfig) => {
  const key = config?.skip
    ? null
    : config?.overrideKey
    ? config.overrideKey
    : ["me", arg];

  const parsedConfig = { ...config };

  if (config?.disableCache) {
    if (!parsedConfig.use?.length) {
      parsedConfig.use = [disableCache];
    } else {
      parsedConfig.use.push(disableCache);
    }
  }

  return useSWR(
    key,
    async ([_, arg]) => {
      const { data } = await norskGassnettApi.me(arg);
      return data;
    },
    parsedConfig
  ) as UseMeFetcherResponse;
};
/**
 * useMeMutation
 * See https://swr.vercel.app/docs/mutation
 */
export const useMeMutation = (config?: UseMeMutationConfig) => {
  const key = config?.overrideKey ? config.overrideKey : ["me"];

  return useSWRMutation(
    key,
    async (_, { arg }) => {
      const { data } = await norskGassnettApi.me(arg);
      return data;
    },
    config
  ) as UseMeMutationResponse;
};

export type UseSwitchCustomerData = GetDataT<
  NorskGassnettApi["switchCustomer"]
>;
export type UseSwitchCustomerArg = GetArgT<NorskGassnettApi["switchCustomer"]>;
export type UseSwitchCustomerKey = [string, UseSwitchCustomerArg];
export type UseSwitchCustomerFetcherConfig = FetcherConfigExtended<
  SWRConfiguration<UseSwitchCustomerData>
>;
export type UseSwitchCustomerFetcherResponse = SWRResponse<
  UseSwitchCustomerData,
  any
>;
export type UseSwitchCustomerMutationConfig = MutationConfigExtended<
  SWRMutationConfiguration<
    UseSwitchCustomerData,
    any,
    UseSwitchCustomerArg,
    string
  >
>;
export type UseSwitchCustomerMutationResponse = SWRMutationResponse<
  UseSwitchCustomerData,
  any,
  UseSwitchCustomerArg
>;
/**
 * useSwitchCustomerFetcher
 * See https://swr.vercel.app/docs/data-fetching
 */
export const useSwitchCustomerFetcher = (
  arg?: UseSwitchCustomerArg,
  config?: UseSwitchCustomerFetcherConfig
) => {
  const key = config?.skip
    ? null
    : config?.overrideKey
    ? config.overrideKey
    : ["switchCustomer", arg];

  const parsedConfig = { ...config };

  if (config?.disableCache) {
    if (!parsedConfig.use?.length) {
      parsedConfig.use = [disableCache];
    } else {
      parsedConfig.use.push(disableCache);
    }
  }

  return useSWR(
    key,
    async ([_, arg]) => {
      const { data } = await norskGassnettApi.switchCustomer(arg);
      return data;
    },
    parsedConfig
  ) as UseSwitchCustomerFetcherResponse;
};
/**
 * useSwitchCustomerMutation
 * See https://swr.vercel.app/docs/mutation
 */
export const useSwitchCustomerMutation = (
  config?: UseSwitchCustomerMutationConfig
) => {
  const key = config?.overrideKey ? config.overrideKey : ["switchCustomer"];

  return useSWRMutation(
    key,
    async (_, { arg }) => {
      const { data } = await norskGassnettApi.switchCustomer(arg);
      return data;
    },
    config
  ) as UseSwitchCustomerMutationResponse;
};

export type UseStartLoginCustomerFlowData = GetDataT<
  NorskGassnettApi["startLoginCustomerFlow"]
>;
export type UseStartLoginCustomerFlowArg = GetArgT<
  NorskGassnettApi["startLoginCustomerFlow"]
>;
export type UseStartLoginCustomerFlowKey = [
  string,
  UseStartLoginCustomerFlowArg
];
export type UseStartLoginCustomerFlowFetcherConfig = FetcherConfigExtended<
  SWRConfiguration<UseStartLoginCustomerFlowData>
>;
export type UseStartLoginCustomerFlowFetcherResponse = SWRResponse<
  UseStartLoginCustomerFlowData,
  any
>;
export type UseStartLoginCustomerFlowMutationConfig = MutationConfigExtended<
  SWRMutationConfiguration<
    UseStartLoginCustomerFlowData,
    any,
    UseStartLoginCustomerFlowArg,
    string
  >
>;
export type UseStartLoginCustomerFlowMutationResponse = SWRMutationResponse<
  UseStartLoginCustomerFlowData,
  any,
  UseStartLoginCustomerFlowArg
>;
/**
 * useStartLoginCustomerFlowFetcher
 * See https://swr.vercel.app/docs/data-fetching
 */
export const useStartLoginCustomerFlowFetcher = (
  arg?: UseStartLoginCustomerFlowArg,
  config?: UseStartLoginCustomerFlowFetcherConfig
) => {
  const key = config?.skip
    ? null
    : config?.overrideKey
    ? config.overrideKey
    : ["startLoginCustomerFlow", arg];

  const parsedConfig = { ...config };

  if (config?.disableCache) {
    if (!parsedConfig.use?.length) {
      parsedConfig.use = [disableCache];
    } else {
      parsedConfig.use.push(disableCache);
    }
  }

  return useSWR(
    key,
    async ([_, arg]) => {
      const { data } = await norskGassnettApi.startLoginCustomerFlow(arg);
      return data;
    },
    parsedConfig
  ) as UseStartLoginCustomerFlowFetcherResponse;
};
/**
 * useStartLoginCustomerFlowMutation
 * See https://swr.vercel.app/docs/mutation
 */
export const useStartLoginCustomerFlowMutation = (
  config?: UseStartLoginCustomerFlowMutationConfig
) => {
  const key = config?.overrideKey
    ? config.overrideKey
    : ["startLoginCustomerFlow"];

  return useSWRMutation(
    key,
    async (_, { arg }) => {
      const { data } = await norskGassnettApi.startLoginCustomerFlow(arg);
      return data;
    },
    config
  ) as UseStartLoginCustomerFlowMutationResponse;
};

export type UseCompleteLoginCustomerFlowData = GetDataT<
  NorskGassnettApi["completeLoginCustomerFlow"]
>;
export type UseCompleteLoginCustomerFlowArg = GetArgT<
  NorskGassnettApi["completeLoginCustomerFlow"]
>;
export type UseCompleteLoginCustomerFlowKey = [
  string,
  UseCompleteLoginCustomerFlowArg
];
export type UseCompleteLoginCustomerFlowFetcherConfig = FetcherConfigExtended<
  SWRConfiguration<UseCompleteLoginCustomerFlowData>
>;
export type UseCompleteLoginCustomerFlowFetcherResponse = SWRResponse<
  UseCompleteLoginCustomerFlowData,
  any
>;
export type UseCompleteLoginCustomerFlowMutationConfig = MutationConfigExtended<
  SWRMutationConfiguration<
    UseCompleteLoginCustomerFlowData,
    any,
    UseCompleteLoginCustomerFlowArg,
    string
  >
>;
export type UseCompleteLoginCustomerFlowMutationResponse = SWRMutationResponse<
  UseCompleteLoginCustomerFlowData,
  any,
  UseCompleteLoginCustomerFlowArg
>;
/**
 * useCompleteLoginCustomerFlowFetcher
 * See https://swr.vercel.app/docs/data-fetching
 */
export const useCompleteLoginCustomerFlowFetcher = (
  arg?: UseCompleteLoginCustomerFlowArg,
  config?: UseCompleteLoginCustomerFlowFetcherConfig
) => {
  const key = config?.skip
    ? null
    : config?.overrideKey
    ? config.overrideKey
    : ["completeLoginCustomerFlow", arg];

  const parsedConfig = { ...config };

  if (config?.disableCache) {
    if (!parsedConfig.use?.length) {
      parsedConfig.use = [disableCache];
    } else {
      parsedConfig.use.push(disableCache);
    }
  }

  return useSWR(
    key,
    async ([_, arg]) => {
      const { data } = await norskGassnettApi.completeLoginCustomerFlow(arg);
      return data;
    },
    parsedConfig
  ) as UseCompleteLoginCustomerFlowFetcherResponse;
};
/**
 * useCompleteLoginCustomerFlowMutation
 * See https://swr.vercel.app/docs/mutation
 */
export const useCompleteLoginCustomerFlowMutation = (
  config?: UseCompleteLoginCustomerFlowMutationConfig
) => {
  const key = config?.overrideKey
    ? config.overrideKey
    : ["completeLoginCustomerFlow"];

  return useSWRMutation(
    key,
    async (_, { arg }) => {
      const { data } = await norskGassnettApi.completeLoginCustomerFlow(arg);
      return data;
    },
    config
  ) as UseCompleteLoginCustomerFlowMutationResponse;
};

export type UseListInvoicesData = GetDataT<NorskGassnettApi["listInvoices"]>;
export type UseListInvoicesArg = GetArgT<NorskGassnettApi["listInvoices"]>;
export type UseListInvoicesKey = [string, UseListInvoicesArg];
export type UseListInvoicesFetcherConfig = FetcherConfigExtended<
  SWRConfiguration<UseListInvoicesData>
>;
export type UseListInvoicesFetcherResponse = SWRResponse<
  UseListInvoicesData,
  any
>;
export type UseListInvoicesMutationConfig = MutationConfigExtended<
  SWRMutationConfiguration<UseListInvoicesData, any, UseListInvoicesArg, string>
>;
export type UseListInvoicesMutationResponse = SWRMutationResponse<
  UseListInvoicesData,
  any,
  UseListInvoicesArg
>;
/**
 * useListInvoicesFetcher
 * See https://swr.vercel.app/docs/data-fetching
 */
export const useListInvoicesFetcher = (
  arg?: UseListInvoicesArg,
  config?: UseListInvoicesFetcherConfig
) => {
  const key = config?.skip
    ? null
    : config?.overrideKey
    ? config.overrideKey
    : ["listInvoices", arg];

  const parsedConfig = { ...config };

  if (config?.disableCache) {
    if (!parsedConfig.use?.length) {
      parsedConfig.use = [disableCache];
    } else {
      parsedConfig.use.push(disableCache);
    }
  }

  return useSWR(
    key,
    async ([_, arg]) => {
      const { data } = await norskGassnettApi.listInvoices(arg);
      return data;
    },
    parsedConfig
  ) as UseListInvoicesFetcherResponse;
};
/**
 * useListInvoicesMutation
 * See https://swr.vercel.app/docs/mutation
 */
export const useListInvoicesMutation = (
  config?: UseListInvoicesMutationConfig
) => {
  const key = config?.overrideKey ? config.overrideKey : ["listInvoices"];

  return useSWRMutation(
    key,
    async (_, { arg }) => {
      const { data } = await norskGassnettApi.listInvoices(arg);
      return data;
    },
    config
  ) as UseListInvoicesMutationResponse;
};

export type UseReadSalesInvoiceData = GetDataT<
  NorskGassnettApi["readSalesInvoice"]
>;
export type UseReadSalesInvoiceArg = GetArgT<
  NorskGassnettApi["readSalesInvoice"]
>;
export type UseReadSalesInvoiceKey = [string, UseReadSalesInvoiceArg];
export type UseReadSalesInvoiceFetcherConfig = FetcherConfigExtended<
  SWRConfiguration<UseReadSalesInvoiceData>
>;
export type UseReadSalesInvoiceFetcherResponse = SWRResponse<
  UseReadSalesInvoiceData,
  any
>;
export type UseReadSalesInvoiceMutationConfig = MutationConfigExtended<
  SWRMutationConfiguration<
    UseReadSalesInvoiceData,
    any,
    UseReadSalesInvoiceArg,
    string
  >
>;
export type UseReadSalesInvoiceMutationResponse = SWRMutationResponse<
  UseReadSalesInvoiceData,
  any,
  UseReadSalesInvoiceArg
>;
/**
 * useReadSalesInvoiceFetcher
 * See https://swr.vercel.app/docs/data-fetching
 */
export const useReadSalesInvoiceFetcher = (
  arg?: UseReadSalesInvoiceArg,
  config?: UseReadSalesInvoiceFetcherConfig
) => {
  const key = config?.skip
    ? null
    : config?.overrideKey
    ? config.overrideKey
    : ["readSalesInvoice", arg];

  const parsedConfig = { ...config };

  if (config?.disableCache) {
    if (!parsedConfig.use?.length) {
      parsedConfig.use = [disableCache];
    } else {
      parsedConfig.use.push(disableCache);
    }
  }

  return useSWR(
    key,
    async ([_, arg]) => {
      const { data } = await norskGassnettApi.readSalesInvoice(arg);
      return data;
    },
    parsedConfig
  ) as UseReadSalesInvoiceFetcherResponse;
};
/**
 * useReadSalesInvoiceMutation
 * See https://swr.vercel.app/docs/mutation
 */
export const useReadSalesInvoiceMutation = (
  config?: UseReadSalesInvoiceMutationConfig
) => {
  const key = config?.overrideKey ? config.overrideKey : ["readSalesInvoice"];

  return useSWRMutation(
    key,
    async (_, { arg }) => {
      const { data } = await norskGassnettApi.readSalesInvoice(arg);
      return data;
    },
    config
  ) as UseReadSalesInvoiceMutationResponse;
};

export type UseReadSalesCreditMemoData = GetDataT<
  NorskGassnettApi["readSalesCreditMemo"]
>;
export type UseReadSalesCreditMemoArg = GetArgT<
  NorskGassnettApi["readSalesCreditMemo"]
>;
export type UseReadSalesCreditMemoKey = [string, UseReadSalesCreditMemoArg];
export type UseReadSalesCreditMemoFetcherConfig = FetcherConfigExtended<
  SWRConfiguration<UseReadSalesCreditMemoData>
>;
export type UseReadSalesCreditMemoFetcherResponse = SWRResponse<
  UseReadSalesCreditMemoData,
  any
>;
export type UseReadSalesCreditMemoMutationConfig = MutationConfigExtended<
  SWRMutationConfiguration<
    UseReadSalesCreditMemoData,
    any,
    UseReadSalesCreditMemoArg,
    string
  >
>;
export type UseReadSalesCreditMemoMutationResponse = SWRMutationResponse<
  UseReadSalesCreditMemoData,
  any,
  UseReadSalesCreditMemoArg
>;
/**
 * useReadSalesCreditMemoFetcher
 * See https://swr.vercel.app/docs/data-fetching
 */
export const useReadSalesCreditMemoFetcher = (
  arg?: UseReadSalesCreditMemoArg,
  config?: UseReadSalesCreditMemoFetcherConfig
) => {
  const key = config?.skip
    ? null
    : config?.overrideKey
    ? config.overrideKey
    : ["readSalesCreditMemo", arg];

  const parsedConfig = { ...config };

  if (config?.disableCache) {
    if (!parsedConfig.use?.length) {
      parsedConfig.use = [disableCache];
    } else {
      parsedConfig.use.push(disableCache);
    }
  }

  return useSWR(
    key,
    async ([_, arg]) => {
      const { data } = await norskGassnettApi.readSalesCreditMemo(arg);
      return data;
    },
    parsedConfig
  ) as UseReadSalesCreditMemoFetcherResponse;
};
/**
 * useReadSalesCreditMemoMutation
 * See https://swr.vercel.app/docs/mutation
 */
export const useReadSalesCreditMemoMutation = (
  config?: UseReadSalesCreditMemoMutationConfig
) => {
  const key = config?.overrideKey
    ? config.overrideKey
    : ["readSalesCreditMemo"];

  return useSWRMutation(
    key,
    async (_, { arg }) => {
      const { data } = await norskGassnettApi.readSalesCreditMemo(arg);
      return data;
    },
    config
  ) as UseReadSalesCreditMemoMutationResponse;
};

export type UseCreateMeterReadingData = GetDataT<
  NorskGassnettApi["createMeterReading"]
>;
export type UseCreateMeterReadingArg = GetArgT<
  NorskGassnettApi["createMeterReading"]
>;
export type UseCreateMeterReadingKey = [string, UseCreateMeterReadingArg];
export type UseCreateMeterReadingFetcherConfig = FetcherConfigExtended<
  SWRConfiguration<UseCreateMeterReadingData>
>;
export type UseCreateMeterReadingFetcherResponse = SWRResponse<
  UseCreateMeterReadingData,
  any
>;
export type UseCreateMeterReadingMutationConfig = MutationConfigExtended<
  SWRMutationConfiguration<
    UseCreateMeterReadingData,
    any,
    UseCreateMeterReadingArg,
    string
  >
>;
export type UseCreateMeterReadingMutationResponse = SWRMutationResponse<
  UseCreateMeterReadingData,
  any,
  UseCreateMeterReadingArg
>;
/**
 * useCreateMeterReadingFetcher
 * See https://swr.vercel.app/docs/data-fetching
 */
export const useCreateMeterReadingFetcher = (
  arg?: UseCreateMeterReadingArg,
  config?: UseCreateMeterReadingFetcherConfig
) => {
  const key = config?.skip
    ? null
    : config?.overrideKey
    ? config.overrideKey
    : ["createMeterReading", arg];

  const parsedConfig = { ...config };

  if (config?.disableCache) {
    if (!parsedConfig.use?.length) {
      parsedConfig.use = [disableCache];
    } else {
      parsedConfig.use.push(disableCache);
    }
  }

  return useSWR(
    key,
    async ([_, arg]) => {
      const { data } = await norskGassnettApi.createMeterReading(arg);
      return data;
    },
    parsedConfig
  ) as UseCreateMeterReadingFetcherResponse;
};
/**
 * useCreateMeterReadingMutation
 * See https://swr.vercel.app/docs/mutation
 */
export const useCreateMeterReadingMutation = (
  config?: UseCreateMeterReadingMutationConfig
) => {
  const key = config?.overrideKey ? config.overrideKey : ["createMeterReading"];

  return useSWRMutation(
    key,
    async (_, { arg }) => {
      const { data } = await norskGassnettApi.createMeterReading(arg);
      return data;
    },
    config
  ) as UseCreateMeterReadingMutationResponse;
};

export type UseListMeterReadingsData = GetDataT<
  NorskGassnettApi["listMeterReadings"]
>;
export type UseListMeterReadingsArg = GetArgT<
  NorskGassnettApi["listMeterReadings"]
>;
export type UseListMeterReadingsKey = [string, UseListMeterReadingsArg];
export type UseListMeterReadingsFetcherConfig = FetcherConfigExtended<
  SWRConfiguration<UseListMeterReadingsData>
>;
export type UseListMeterReadingsFetcherResponse = SWRResponse<
  UseListMeterReadingsData,
  any
>;
export type UseListMeterReadingsMutationConfig = MutationConfigExtended<
  SWRMutationConfiguration<
    UseListMeterReadingsData,
    any,
    UseListMeterReadingsArg,
    string
  >
>;
export type UseListMeterReadingsMutationResponse = SWRMutationResponse<
  UseListMeterReadingsData,
  any,
  UseListMeterReadingsArg
>;
/**
 * useListMeterReadingsFetcher
 * See https://swr.vercel.app/docs/data-fetching
 */
export const useListMeterReadingsFetcher = (
  arg?: UseListMeterReadingsArg,
  config?: UseListMeterReadingsFetcherConfig
) => {
  const key = config?.skip
    ? null
    : config?.overrideKey
    ? config.overrideKey
    : ["listMeterReadings", arg];

  const parsedConfig = { ...config };

  if (config?.disableCache) {
    if (!parsedConfig.use?.length) {
      parsedConfig.use = [disableCache];
    } else {
      parsedConfig.use.push(disableCache);
    }
  }

  return useSWR(
    key,
    async ([_, arg]) => {
      const { data } = await norskGassnettApi.listMeterReadings(arg);
      return data;
    },
    parsedConfig
  ) as UseListMeterReadingsFetcherResponse;
};
/**
 * useListMeterReadingsMutation
 * See https://swr.vercel.app/docs/mutation
 */
export const useListMeterReadingsMutation = (
  config?: UseListMeterReadingsMutationConfig
) => {
  const key = config?.overrideKey ? config.overrideKey : ["listMeterReadings"];

  return useSWRMutation(
    key,
    async (_, { arg }) => {
      const { data } = await norskGassnettApi.listMeterReadings(arg);
      return data;
    },
    config
  ) as UseListMeterReadingsMutationResponse;
};

export type UseListConsumptionsData = GetDataT<
  NorskGassnettApi["listConsumptions"]
>;
export type UseListConsumptionsArg = GetArgT<
  NorskGassnettApi["listConsumptions"]
>;
export type UseListConsumptionsKey = [string, UseListConsumptionsArg];
export type UseListConsumptionsFetcherConfig = FetcherConfigExtended<
  SWRConfiguration<UseListConsumptionsData>
>;
export type UseListConsumptionsFetcherResponse = SWRResponse<
  UseListConsumptionsData,
  any
>;
export type UseListConsumptionsMutationConfig = MutationConfigExtended<
  SWRMutationConfiguration<
    UseListConsumptionsData,
    any,
    UseListConsumptionsArg,
    string
  >
>;
export type UseListConsumptionsMutationResponse = SWRMutationResponse<
  UseListConsumptionsData,
  any,
  UseListConsumptionsArg
>;
/**
 * useListConsumptionsFetcher
 * See https://swr.vercel.app/docs/data-fetching
 */
export const useListConsumptionsFetcher = (
  arg?: UseListConsumptionsArg,
  config?: UseListConsumptionsFetcherConfig
) => {
  const key = config?.skip
    ? null
    : config?.overrideKey
    ? config.overrideKey
    : ["listConsumptions", arg];

  const parsedConfig = { ...config };

  if (config?.disableCache) {
    if (!parsedConfig.use?.length) {
      parsedConfig.use = [disableCache];
    } else {
      parsedConfig.use.push(disableCache);
    }
  }

  return useSWR(
    key,
    async ([_, arg]) => {
      const { data } = await norskGassnettApi.listConsumptions(arg);
      return data;
    },
    parsedConfig
  ) as UseListConsumptionsFetcherResponse;
};
/**
 * useListConsumptionsMutation
 * See https://swr.vercel.app/docs/mutation
 */
export const useListConsumptionsMutation = (
  config?: UseListConsumptionsMutationConfig
) => {
  const key = config?.overrideKey ? config.overrideKey : ["listConsumptions"];

  return useSWRMutation(
    key,
    async (_, { arg }) => {
      const { data } = await norskGassnettApi.listConsumptions(arg);
      return data;
    },
    config
  ) as UseListConsumptionsMutationResponse;
};
