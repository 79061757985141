import React, { FormEventHandler, useRef, useState } from "react";
import {
  Alert,
  Avatar,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Stack,
  StandardTextFieldProps,
  TextField,
  Typography,
} from "@mui/material";
import { getBorderRadius } from "../../lib/helpers/utils";
import { useTranslation } from "react-i18next";
import SubpageWrapper from "../../lib/components/atomics/SubpageWrapper";
import MUIIcon from "../../lib/components/atomics/MUIIcon";
import LoadingButton from "../../lib/components/atomics/LoadingButton";
import { useRelocateCustomerMutation } from "../../lib/apis/norskGassnettApiHooks";
import DatePicker from "../../lib/components/complex/DatePicker";
import MUITelInput from "../../lib/components/atomics/MUITelInput";
import { EMAIL_REGEX } from "../../lib/helpers/regex";
import { matchIsValidTel } from "mui-tel-input";
import { Dayjs } from "dayjs";
import { RelocateCustomerOptions } from "../../../../backend/src/lib/apis/NorskGassnettApi";
import TranslatedLink from "../../lib/components/complex/TranslatedLink";
import { PATH as PATH_ROOT } from "../root";
import CollapsableAlert from "../../lib/components/complex/CollapsableAlert";

export const PATH_SEGMENT = `flytteskjema`;
export const PATH = `/${PATH_SEGMENT}`;
export const getPath = () => PATH;

interface SectionProps {
  index: number;
  title: string;
  required?: boolean;
  info?: string;
  children: React.ReactNode;
}

const Section: React.FC<SectionProps> = ({
  index,
  title,
  required,
  info,
  children,
}) => {
  const { t } = useTranslation();
  const color = index === 1 ? "primary" : "secondary";
  const borderPadding = "40px";
  return (
    <Paper sx={{ width: 1, p: 0, borderRadius: getBorderRadius }}>
      <Stack
        sx={{ width: 1, p: borderPadding, pb: 0 }}
        direction={{ xs: "column", md: "row" }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack
          direction="row"
          alignItems="center"
          gap={2}
          sx={{ placeSelf: "start" }}
        >
          <Avatar
            sx={{
              color: `${color}.contrastText`,
              backgroundColor: `${color}.main`,
            }}
          >
            {index}
          </Avatar>
          <Typography variant="h3">{title}</Typography>
        </Stack>
        {required && (
          <Typography sx={{ fontStyle: "italic" }}>
            {t("relocate.required_field")}
          </Typography>
        )}
      </Stack>
      <Typography
        textAlign={{ xs: "center", md: "left" }}
        variant="body1"
        mx={borderPadding}
        mt={2}
        mb={3}
      >
        {info}
      </Typography>
      <Divider />
      <Grid sx={{ p: borderPadding }} container spacing={3}>
        {children}
      </Grid>
    </Paper>
  );
};

interface SectionInputProps
  extends Omit<StandardTextFieldProps, "type" | "error"> {
  name: string;
  type?: React.ReactNode;
  sm?: number;
  md?: number;
  required?: boolean;
  info?: string;
}

const SectionInput: React.FC<SectionInputProps> = ({
  name,
  type,
  sm,
  md,
  required = true,
  info,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <Grid item xs={12} sm={sm} md={md}>
      <Stack direction="row" justifyContent="space-between">
        <Typography>
          {t(`relocate.${name}`)}
          {required && (
            <Typography component="span" color="error">
              {" "}
              *
            </Typography>
          )}
        </Typography>
        {info && (
          <Typography sx={{ display: { xs: "none", md: "block" } }}>
            {info}
          </Typography>
        )}
      </Stack>
      {type ?? <TextField type="text" name={name} fullWidth {...props} />}
      {info && <Typography sx={{ display: { md: "none" } }}>{info}</Typography>}
    </Grid>
  );
};

interface ClearFormProps {
  onClick: () => void;
  disabled?: boolean;
}

const ClearForm: React.FC<ClearFormProps> = ({ onClick, disabled }) => {
  const { t } = useTranslation();
  return (
    <Button
      onClick={onClick}
      variant="text"
      disabled={disabled}
      startIcon={<MUIIcon name="delete_forever" color="error" filled />}
    >
      {t("relocate.empty_form")}
    </Button>
  );
};

const isValidDate = (s: string) => {
  const [valid, d, m, y] = s.match(/^(\d{2})\.(\d{2})\.(\d{4})$/) ?? [];
  if (!valid) return false;
  if (new Date(`${y}-${m}-${d}`).toString() === "Invalid Date") return false;
  return true;
};

const getFormErrors = (
  data: FormData,
  overrideFields?: Record<string, string | undefined | null>
) => {
  const get = (value: string) =>
    overrideFields?.[value] ?? (data.get(value) as string);
  const isEmpty = (value: string) => !get(value).length;
  const errors: Record<string, boolean> = {};

  if (!isValidDate(get("relocation_date"))) errors.relocation_date = true;
  if (!isValidDate(get("new_owner_birthdate")))
    errors.new_owner_birthdate = true;
  if (!matchIsValidTel(get("new_owner_tlf"))) errors.new_owner_tlf = true;
  if (!EMAIL_REGEX.test(get("new_owner_email"))) errors.new_owner_email = true;

  const meterReading = parseInt(get("meter_reading"));
  const newOwnerPostal = get("new_owner_postal_code");

  if (isNaN(meterReading) || meterReading < 0) errors.meter_reading = true;
  if (isEmpty("invoice_address")) errors.invoice_address = true;
  if (!/^\d{4}$/.test(get("postal_code"))) errors.postal_code = true;
  if (isEmpty("place")) errors.place = true;
  if (isEmpty("new_owner_name")) errors.new_owner_name = true;
  if (
    isEmpty("new_owner_address") !== !newOwnerPostal ||
    !newOwnerPostal !== isEmpty("new_owner_place")
  ) {
    errors.new_owner_address = isEmpty("new_owner_address");
    errors.new_owner_postal_code = !/^\d{4}$/.test(newOwnerPostal);
    errors.new_owner_place = isEmpty("new_owner_place");
  }
  return errors;
};

const errorsChanged = (
  oldErrors: Record<string, boolean>,
  newErrors: Record<string, boolean>
) => {
  if (Object.keys(oldErrors).length !== Object.keys(newErrors).length)
    return true;
  for (const key in oldErrors) {
    const oldError = oldErrors[key];
    const newError = newErrors[key];
    if (oldError !== newError) return true;
  }
  return false;
};

const Relocate = () => {
  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement>();

  const [newOwnerIsAware, setNewOwnerIsAware] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const [completedForm, setCompletedForm] = useState(false);
  // only used because the MUITlfInput component only works with value && onChange
  const [newOwnerTlf, setNewOwnerTlf] = useState("");
  const [relocationDate, setRelocationDate] = useState<Dayjs | null>(null);
  const [newOwnerBirthdate, setNewOwnerBirthdate] = useState<Dayjs | null>(
    null
  );
  const relocateCustomer = useRelocateCustomerMutation();

  const getProps = (name: string) => ({
    name,
    error: errors[name],
  });

  const handleNewOwnerIsAware = (_: unknown, checked: boolean) => {
    setNewOwnerIsAware(checked);
    checkFormValidity({ new_owner_is_aware: checked ? "true" : "" });
  };

  const clearForm = () => {
    setNewOwnerIsAware(false);
    setCanSubmit(false);
    setNewOwnerTlf("");
    setRelocationDate(null);
    setNewOwnerBirthdate(null);

    formRef.current?.reset();
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    if (!canSubmit) return;
    const data = new FormData(e.target as HTMLFormElement);
    const output: Record<string, string> = {};
    for (const [key, _value] of data.entries() as IterableIterator<
      [string, string]
    >) {
      if (!_value) continue;
      let value = _value;
      const camelCase = key.replace(/_([a-z])/g, (_, l) => l.toUpperCase());
      if (key === "new_owner_tlf") {
        value = value.replace(/ /g, "");
      }
      output[camelCase] = value;
    }
    await relocateCustomer.trigger({
      body: output as unknown as RelocateCustomerOptions["body"],
    });
    setCompletedForm(true);
  };

  const checkFormValidity = (
    overrideFields?: Record<string, string | undefined | null>
  ) => {
    if (!formRef) return;
    const data = new FormData(formRef.current);
    const newErrors = getFormErrors(data, overrideFields);
    const noErrors = !Object.keys(newErrors).length;
    if (errorsChanged(errors, newErrors)) {
      setErrors(newErrors);
    }
    setCanSubmit(
      !!(overrideFields?.new_owner_is_aware ?? newOwnerIsAware) && noErrors
    );
  };

  return (
    <SubpageWrapper
      title={t("relocate.title")}
      backRef={PATH_ROOT}
      specialAction={
        !completedForm && (
          <ClearForm
            onClick={clearForm}
            disabled={relocateCustomer.isMutating}
          />
        )
      }
    >
      {completedForm ? (
        <Paper sx={{ width: 1, p: 3, borderRadius: getBorderRadius }}>
          <Alert>
            <TranslatedLink
              linkProps={{ underline: "always" }}
              href={PATH_ROOT}
              content="relocate.success"
            />
          </Alert>
        </Paper>
      ) : (
        <Stack
          component="form"
          gap={3}
          ref={formRef}
          onSubmit={handleSubmit}
          onChange={(e) => {
            if (e.target.type === "checkbox") return;
            checkFormValidity();
          }}
        >
          <Section
            index={1}
            title={t("relocate.your_info")}
            info={t("relocate.meter_reading_info")}
            required
          >
            <SectionInput {...getProps("meter_reading")} sm={12} md={6} />
            <SectionInput
              name="relocation_date"
              type={
                <DatePicker
                  name="relocation_date"
                  error={errors.relocation_date}
                  required
                  value={relocationDate}
                  onChange={(newDate) => {
                    setRelocationDate(newDate);
                    checkFormValidity({
                      relocation_date: newDate?.format("DD.MM.YYYY"),
                    });
                  }}
                  fullWidth
                />
              }
              sm={12}
              md={6}
            />
            <SectionInput {...getProps("invoice_address")} />
            <SectionInput
              {...getProps("postal_code")}
              autoComplete="postal-code"
              sm={12}
              md={6}
            />
            <SectionInput {...getProps("place")} sm={12} md={6} />
          </Section>
          <Section
            index={2}
            title={t("relocate.new_owner_info")}
            info={t("relocate.missing_owner_info")}
          >
            <SectionInput {...getProps("new_owner_name")} sm={12} md={6} />
            <SectionInput
              name="new_owner_birthdate"
              type={
                <DatePicker
                  name="new_owner_birthdate"
                  error={errors.new_owner_birthdate}
                  value={newOwnerBirthdate}
                  onChange={(newDate) => {
                    setNewOwnerBirthdate(newDate);
                    checkFormValidity({
                      new_owner_birthdate: newDate?.format("DD.MM.YYYY"),
                    });
                  }}
                  fullWidth
                />
              }
              sm={12}
              md={6}
            />
            <SectionInput
              name="new_owner_tlf"
              type={
                <MUITelInput
                  {...getProps("new_owner_tlf")}
                  value={newOwnerTlf}
                  onChange={(newTlf) => {
                    setNewOwnerTlf(newTlf);
                    checkFormValidity({ new_owner_tlf: newTlf });
                  }}
                  fullWidth
                />
              }
              md={5}
            />
            <SectionInput {...getProps("new_owner_email")} md={7} />
            <SectionInput
              {...getProps("new_owner_address")}
              required={false}
              info={t("relocate.new_owner_address_filled_if")}
            />
            <SectionInput
              {...getProps("new_owner_postal_code")}
              sm={12}
              md={6}
              required={false}
            />
            <SectionInput
              {...getProps("new_owner_place")}
              sm={12}
              md={6}
              required={false}
            />
            <Grid item xs={12}>
              <FormControlLabel
                disabled={relocateCustomer.isMutating}
                checked={newOwnerIsAware}
                onChange={handleNewOwnerIsAware}
                control={<Checkbox />}
                label={t("relocate.new_owner_is_informed")}
              />
            </Grid>
          </Section>
          <Stack my={2}>
            <CollapsableAlert
              alert={relocateCustomer.error && t("relocate.invalid_values")}
            />
          </Stack>
          <Stack
            p={2}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <ClearForm
              onClick={clearForm}
              disabled={relocateCustomer.isMutating}
            />
            <LoadingButton
              type="submit"
              endIcon={<MUIIcon name="arrow_forward" />}
              disabled={!canSubmit}
              loading={relocateCustomer.isMutating}
            >
              {t("relocate.submit_form")}
            </LoadingButton>
          </Stack>
        </Stack>
      )}
    </SubpageWrapper>
  );
};

export default Relocate;
