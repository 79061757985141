import { Outlet } from "react-router-dom";
import { addSlashAtEnd } from "../lib/helpers/utils";
import { ROUTE_BASENAME } from "../constants";

export const PATH = addSlashAtEnd(ROUTE_BASENAME);

export default function Root() {
  return (
    <div id="root-route-component">
      <Outlet />
    </div>
  );
}
