import { useEffect, useRef } from "react";
import { useReauthenticateFetcher } from "../apis/norskGassnettApiHooks";
import { TOKEN_AUTHORIZATION_VALIDITY } from "../../../../backend/src/constants";

export interface UseAuthenticationProps {
  token: string | null;
  setToken: (value: string | null) => void;
}

const useReauthentication = ({ token, setToken }: UseAuthenticationProps) => {
  const { current: key } = useRef(Date.now().toString());

  const reauthenticate = useReauthenticateFetcher(undefined, {
    skip: !token,
    refreshInterval: TOKEN_AUTHORIZATION_VALIDITY * 0.9,
    errorRetryCount: 1,
    revalidateOnFocus: false,
    overrideKey: ["useReauthenticateFetcher", key],
  });

  useEffect(() => {
    if (reauthenticate.isLoading || reauthenticate.isValidating) {
      return;
    } else if (reauthenticate.error) {
      setToken(null);
    } else if (reauthenticate.data?.token) {
      setToken(reauthenticate.data.token);
    }
  }, [reauthenticate, setToken]);
};

export default useReauthentication;
