import {
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Tooltip,
} from "@mui/material";
import {
  InvoiceType,
  PATH as PATH_INVOICES,
  SALES_CREDIT_MEMO,
  SALES_INVOICE,
} from "./invoices";
import {
  downloadFile,
  getBorderRadius,
  numberToPrice,
} from "../../lib/helpers/utils";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Loader from "../../lib/components/atomics/Loader";
import SubpageWrapper from "../../lib/components/atomics/SubpageWrapper";
import {
  useReadSalesCreditMemoFetcher,
  useReadSalesInvoiceFetcher,
} from "../../lib/apis/norskGassnettApiHooks";
import MUIIcon from "../../lib/components/atomics/MUIIcon";
import CollapsableAlert from "../../lib/components/complex/CollapsableAlert";
import { useErrorMessage } from "../../lib/hooks/useErrorMessage";
import dayjs from "dayjs";
import LoadingButton from "../../lib/components/atomics/LoadingButton";
import { useGetInvoicePdf } from "../../lib/hooks/useGetInvoicePdf";
import {
  ReadSalesCreditMemoResponseBody,
  ReadSalesInvoiceResponseBody,
} from "../../../../backend/src/invoice/structs";
import React from "react";

export const PATH_SEGMENT = "/faktura/:type/:id";
export const PATH = `${PATH_SEGMENT}`;

export function getPath(invoiceType: InvoiceType, invoiceId: string) {
  return PATH.replace(":type", invoiceType).replace(":id", invoiceId);
}

type UseReadInvoiceType =
  | {
      invoiceType: typeof SALES_INVOICE;
      data?: ReadSalesInvoiceResponseBody["salesInvoice"];
      dataLines?: ReadSalesInvoiceResponseBody["salesInvoiceLines"];
      pdfString?: string;
      isLoading: boolean;
      error: AnyError;
    }
  | {
      invoiceType: typeof SALES_CREDIT_MEMO;
      data?: ReadSalesCreditMemoResponseBody["salesCreditMemo"];
      dataLines?: ReadSalesCreditMemoResponseBody["salesCreditMemoLines"];
      pdfString?: string;
      isLoading: boolean;
      error: AnyError;
    };

const useReadInvoice = (
  invoiceType: InvoiceType,
  invoiceId: string
): UseReadInvoiceType => {
  const isSalesCreditMemo = invoiceType === SALES_CREDIT_MEMO;

  const shouldSkip =
    ![SALES_INVOICE, SALES_CREDIT_MEMO].includes(invoiceType) || !invoiceId;
  const salesInvoice = useReadSalesInvoiceFetcher(
    {
      salesInvoiceId: invoiceId,
    },
    { skip: isSalesCreditMemo || shouldSkip }
  );
  const salesCreditMemo = useReadSalesCreditMemoFetcher(
    {
      salesCreditMemoId: invoiceId,
    },
    { skip: !isSalesCreditMemo || shouldSkip }
  );

  if (isSalesCreditMemo)
    return {
      invoiceType: SALES_CREDIT_MEMO,
      data: salesCreditMemo.data?.salesCreditMemo,
      dataLines: salesCreditMemo.data?.salesCreditMemoLines,
      isLoading: salesCreditMemo.isLoading,
      error: salesCreditMemo.error as AnyError | undefined,
    };
  return {
    invoiceType: SALES_INVOICE,
    data: salesInvoice.data?.salesInvoice,
    dataLines: salesInvoice.data?.salesInvoiceLines,
    isLoading: salesInvoice.isLoading,
    error: salesInvoice.error as AnyError | undefined,
  };
};

interface InfoTableRowProps {
  title: string;
  data: string | number;
  strictTitle?: boolean;
  headerTitle?: boolean;
  dataTitle?: boolean;
  dataTextAlignRight?: boolean;
  titleTooltip?: string;
}

const InfoTableRow: React.FC<InfoTableRowProps> = ({
  title,
  data,
  headerTitle,
  dataTitle,
  strictTitle,
  dataTextAlignRight,
  titleTooltip,
}) => {
  const { t } = useTranslation();
  return (
    <TableRow sx={{ "& td, & th": { border: 0 } }}>
      <TableCell
        component={headerTitle ? "th" : "td"}
        scope="row"
        sx={{ pl: 0 }}
      >
        {titleTooltip ? (
          <Tooltip title={titleTooltip} placement="top-start">
            <Typography variant="h5">
              {strictTitle ? title : t("invoice_details." + title)}
            </Typography>
          </Tooltip>
        ) : (
          <Typography variant="h5">
            {strictTitle ? title : t("invoice_details." + title)}
          </Typography>
        )}
      </TableCell>
      <TableCell component={dataTitle ? "th" : "td"} sx={{ pr: 0 }}>
        <Typography
          variant={dataTitle ? "h5" : "body1"}
          textAlign={dataTextAlignRight ? "right" : "left"}
        >
          {data}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

const InvoiceDetails = () => {
  const { t } = useTranslation();
  const er = useErrorMessage();
  const { type, id } = useParams() as { type: InvoiceType; id: string };

  const { invoiceType, data, dataLines, isLoading, error } = useReadInvoice(
    type,
    id
  );
  const pdf = useGetInvoicePdf();

  const isSalesInvoice = invoiceType !== SALES_CREDIT_MEMO;
  const invoiceTypeString = isSalesInvoice
    ? "invoice_name"
    : "credit_memo_name";
  const invoiceName: string =
    (data?.number &&
      t(`invoice_details.${invoiceTypeString}`).replace(
        "{{invoice}}",
        data.number.toString()
      )) ||
    t(`invoice_details.standard_${invoiceTypeString}`);

  const handleGetPdf = async () => {
    if (!data) return;
    const pdfString = await pdf.trigger({ ...data, invoiceType });
    if (pdfString)
      downloadFile({
        name: invoiceName,
        data: pdfString,
      });
  };

  const isPaid = data?.status === "Paid";

  const lineTypesSorted = ["Resource", "Item", "Account"];

  const tableRows = (dataLines ?? [])
    ?.filter((line) => lineTypesSorted.includes(line.lineType))
    .sort((a, b) => {
      const aIndex = lineTypesSorted.indexOf(a.lineType);
      const bIndex = lineTypesSorted.indexOf(b.lineType);
      return aIndex - bIndex;
    });

  return (
    <SubpageWrapper
      title={t(
        `invoice_details.title_${isSalesInvoice ? "invoice" : "credit_memo"}`
      )}
      backRef={PATH_INVOICES}
      StackProps={{ gap: 3 }}
    >
      <Typography textAlign="center" px={3}>
        {t(
          `invoice_details.subtitle_${
            isSalesInvoice ? "invoice" : "credit_memo"
          }`
        )}
      </Typography>
      <CollapsableAlert alert={er("invoice", error)} />
      {isLoading ? (
        <Loader />
      ) : (
        data && (
          <>
            <Paper sx={{ width: "100%", p: 4, borderRadius: getBorderRadius }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ w: 1 }}
              >
                <Stack direction="row" alignItems="center">
                  {isPaid ? (
                    <MUIIcon name="done" color="success" />
                  ) : (
                    <MUIIcon name="close" color="error" />
                  )}
                  <Typography ml={2}>
                    {t(
                      `invoice_details.${
                        !data ? "not_found" : isPaid ? "paid" : "open"
                      }`
                    )}
                  </Typography>
                </Stack>

                <LoadingButton
                  square
                  variant="outlined"
                  startIcon={
                    <MUIIcon
                      name={pdf.error ? "file_download_off" : "download"}
                    />
                  }
                  loading={pdf.isMutating}
                  title={invoiceName}
                  onClick={handleGetPdf}
                >
                  {t("invoice_details.download")}
                </LoadingButton>
              </Stack>
              <Divider sx={{ my: 2 }} />
              <Typography variant="h3">
                {t(
                  `invoice_details.payment_details_${
                    isSalesInvoice ? "invoice" : "credit_memo"
                  }`
                )}
              </Typography>
              <Table size="small" sx={{ width: "fit-content", mt: 2 }}>
                <TableBody>
                  <InfoTableRow
                    headerTitle
                    title="invoice_number"
                    data={data.number}
                  />
                  <InfoTableRow
                    headerTitle
                    title="due_date"
                    data={dayjs(data.dueDate).format("DD.MM.YYYY")}
                  />
                  {isSalesInvoice && (
                    <>
                      <InfoTableRow
                        headerTitle
                        title="account_number"
                        data={
                          data.companyBankAccountNo ||
                          t("invoice_details.not_availible")
                        }
                      />
                      <InfoTableRow
                        headerTitle
                        title="kid_number"
                        data={data.KID || t("invoice_details.not_availible")}
                      />
                    </>
                  )}
                </TableBody>
              </Table>
            </Paper>
            <Paper sx={{ width: "100%", p: 4, borderRadius: getBorderRadius }}>
              <Typography variant="h3">
                {t("invoice_details.price_details")}
              </Typography>
              <Divider sx={{ my: 2 }} />
              <Table size="small" sx={{ width: "100%" }}>
                <TableBody>
                  {tableRows.map((line) => {
                    let title = line.description;
                    let titleTooltip: string | undefined = undefined;
                    const titleLength = line.description.length;
                    const titleMaxLength = 40;

                    if (titleLength > titleMaxLength) {
                      title =
                        line.description.substring(0, titleMaxLength - 3) +
                        "...";
                      titleTooltip = line.description;
                    }

                    return (
                      <InfoTableRow
                        dataTextAlignRight
                        strictTitle
                        key={line.id}
                        title={title}
                        titleTooltip={titleTooltip}
                        data={numberToPrice(line.netAmount, data.currencyCode)}
                      />
                    );
                  })}
                  <InfoTableRow
                    dataTextAlignRight
                    title="vat_amount"
                    data={numberToPrice(data.totalTaxAmount, data.currencyCode)}
                  />
                  <TableRow>
                    <TableCell colSpan={2} sx={{ px: 0 }}>
                      <Divider />
                    </TableCell>
                  </TableRow>
                  <InfoTableRow
                    dataTextAlignRight
                    headerTitle
                    dataTitle
                    title="total"
                    data={numberToPrice(
                      data.totalAmountIncludingTax,
                      data.currencyCode
                    )}
                  />
                </TableBody>
              </Table>
            </Paper>
          </>
        )
      )}
    </SubpageWrapper>
  );
};

export default InvoiceDetails;
