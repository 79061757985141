import { useCallback } from "react";
import {
  SALES_INVOICE,
  SALES_CREDIT_MEMO,
} from "../../routes/__authenticated/invoices";
import {
  useReadSalesInvoiceMutation,
  useReadSalesCreditMemoMutation,
} from "../apis/norskGassnettApiHooks";

type UseGetInvoicePdfType = (invoice: {
  invoiceType: typeof SALES_INVOICE | typeof SALES_CREDIT_MEMO;
  id: string;
}) => Promise<string | null>;

export const useGetInvoicePdf = () => {
  const salesInvoice = useReadSalesInvoiceMutation();
  const salesCreditMemo = useReadSalesCreditMemoMutation();

  const trigger = useCallback<UseGetInvoicePdfType>(
    async (invoice) => {
      const isSalesCreditMemo = invoice.invoiceType === SALES_CREDIT_MEMO;

      if (isSalesCreditMemo) {
        const res = await salesCreditMemo.trigger({
          salesCreditMemoId: invoice.id,
          options: { parameters: { includePdf: true } },
        });

        return res?.salesCreditMemoPdf ?? null;
      } else {
        const res = await salesInvoice.trigger({
          salesInvoiceId: invoice.id,
          options: { parameters: { includePdf: true } },
        });

        return res?.salesInvoicePdf ?? null;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [salesInvoice.trigger, salesCreditMemo.trigger]
  );

  return {
    trigger,
    isMutating: salesInvoice.isMutating || salesCreditMemo.isMutating,
    error: salesInvoice.error || salesCreditMemo.error,
  };
};
