import { useMeFetcher } from "../apis/norskGassnettApiHooks";

export interface UseIdentityProps {
  token: string | null;
}

export const useIdentity = ({ token }: UseIdentityProps) => {
  const identity = useMeFetcher(
    {
      options: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
    {
      skip: !token,
      errorRetryCount: 0,
      revalidateOnFocus: false,
    }
  );

  return {
    isLoading: identity.isLoading,
    isValidating: identity.isValidating,
    isIdentified: identity.data !== undefined,
    mutate: identity.mutate,
    identity: identity.data?.current,
  };
};
