import { FC, ReactNode } from "react";
import LoadingButton, { LoadingButtonProps } from "./LoadingButton";

export interface LoadingIconButtonProps
  extends Omit<LoadingButtonProps, "variant" | "startIcon" | "endIcon"> {
  filled?: boolean;
  children: ReactNode;
}
const LoadingIconButton: FC<LoadingIconButtonProps> = ({
  sx,
  filled,
  children,
  ...props
}) => {
  return (
    <LoadingButton
      variant={filled ? "contained" : "outlined"}
      startIcon={children}
      sx={{
        ".MuiButton-startIcon": { m: 0 },
        p: "0.45rem 0.5rem",
        minWidth: 0,
        ...sx,
      }}
      {...props}
    />
  );
};

export default LoadingIconButton;
